import React, {useEffect, useState} from 'react';
import './BoxEdit.scss';

// Imports components
import ActionBtn from '../../ActionBtn/ActionBtn'

const BoxEdit = ({food, indexes, actionEdit ,actionRemove}) => {

    const { quantity : fQuantity, name: foodName } = food;

    const { mealIndex, foodIndex } = indexes;

    const [quantity, setQuantity] = useState(fQuantity);
    

    useEffect(()=>{
        setQuantity(fQuantity);
    }, [food]);

    const handleChange = (e) => {
        if (!e.target) return;

        let value = e.target.value;

        setQuantity(value);
    };

    const handleSubmit = (e)=>{
        e.preventDefault();

        handleEdit();

        let input = e.target.quantity;

        input.blur();
    };

    const handleEdit = ()=>{

        actionEdit(mealIndex, foodIndex, quantity);

    };

    const handleRemove = () => {
        actionRemove(mealIndex, foodIndex);

    };

    return (
        <form className='food-edit-form' onSubmit={handleSubmit}>
            <div>
				<span className="food-name">
					{foodName + ':'}
				</span>
				
				<ActionBtn cssClass='remove' type='remove' action={handleRemove} />
			</div>

            <div>
                <input
                    type="number"
                    name='quantity'
                    id='quantity'
                    value={quantity}
                    onChange={handleChange}
                    onBlur={handleEdit}
                    autoComplete='off'
                />
                <span>g</span>
            </div>

        </form>
    )
}

export default BoxEdit