import { useDispatch } from 'react-redux';

/*----------------------------------HOOKS-------------------------------*/

import useFoodRequest from './foodRequest';

/*----------------------------------STATE-------------------------------*/

import { setLoading, setModalMessage } from '../redux/actions/pageState';

// Array of meal code, need it for request to server

export default function useFoodActions(){
	
	const dispatch = useDispatch();

    const { getCustomFoodList, createCustomFood, deleteCustomFood } = useFoodRequest();

	const getFoods = async (cache=true) => {

        dispatch(setLoading(true));

        await getCustomFoodList(cache);
        
        dispatch(setLoading(false));
    };

    const addNewFood = async (form) => {
        let body = {};
        
        for (let input in form) {
            body = {
                ...body,
                [input]: form[input]
            }

        }

        let { foodName, kj, kcal } = body;

        body.ediblePart = "100";
        body.foodName = foodName.toUpperCase();

        if ( !body.kj || kj == 0 || kj === '' ) {

            let calc = kcal * 4.184;
            body.kj = calc.toFixed(2);

        } else if ( kcal == 0 || kcal === '' ) {

            let calc = kj / 4.184;
            body.kcal = calc.toFixed(2);
        }

        dispatch(setLoading(true));

        let res = await createCustomFood(body);

        dispatch(setLoading(false));

        if (res) {
            dispatch(setModalMessage('Alimento creato'));
            return true;
        } else {
            dispatch(setModalMessage('Errore durante la creazione dell\'alimento'));
            return false;
        }
    }

    const deleteFood = async (id)=> {

        await deleteCustomFood(id);
        await getFoods(false);
    };

	

	
    return { getFoods, addNewFood, deleteFood }
}