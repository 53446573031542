import { configureStore } from '@reduxjs/toolkit';
import appSlice from './actions/appSlice';
import appData from './actions/appData';
import pageState from './actions/pageState';

export default configureStore({
  reducer: {
    appReducer: appSlice,
	appData : appData,
	pageState : pageState
  },
})