import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  headers : {
    authorizzation: ""
  },
  id: '',
  registration : {
    page : 1
  },
  user : {},
  logged : false,
};

export const appSlice = createSlice({
  name: 'appReducer',
  initialState: initialState,
  reducers: {
    
    registration: (state, action) => {

      let step = action.payload.step;
      let data = action.payload.data;

      state.registration[step] = data;
    },

    setPage: (state, action) => {

      let page = action.payload.page;

      state.registration.page = page;
    },

    clearRegistation: (state, action) => {
      state.registration = {
        page : 1
      };
    },

    setUserData: (state, action) => {

      state.user = action.payload.data;
    },

    setAuth: (state, action) => {

      state.headers.authorizzation = action.payload.token;

      if (action.payload.id) {

        state.id = action.payload.id;
      }
    }, 

    setLogged: (state, action) => {
		
		if (action.payload.id) {
			state.id = action.payload.id;
		}
	 
      state.logged = action.payload.logged;
    },

    resetState: (state, action) => {
      state.headers = {
        authorizzation: ""
      };
	    state.id = '';
      state.registration = {
        page : 1
      };
      state.user = {};
      state.logged = false;
      state.plans = {};
      state.singleMeal = {};
    },
  },
})

export const { registration, setPage, clearRegistation, setUserData, setAuth, setLogged, resetState } = appSlice.actions

export default appSlice.reducer