import React, { useContext, createContext, useState } from "react";

const PlanContext = createContext();

export const PlanProvider = ({ children }) => {

    const [isFirstTime, setIsFirstTime] = useState(true);
    
    return <PlanContext.Provider value={
        { isFirstTime, setIsFirstTime }   // passiamo in value stati e funzioni da condividere
    }>
        {children}
    </PlanContext.Provider>
};

export const useGlobalContext = () => { return useContext(PlanContext); };
