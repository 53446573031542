import React, { useEffect, useState } from 'react';
import './SingleMealPage.scss';

import { useSelector, useDispatch } from 'react-redux';

import { setSingleMeal, resetMealAction, mealToCreate } from '../../redux/actions/appData';
import { setEditMode, setLoading } from '../../redux/actions/pageState';

import { initialState } from '../../redux/actions/appData';

import { useParams, useNavigate } from 'react-router-dom';

/*-----------------------------IMPORT-CLASSES-----------------------------*/

import { Meal } from '../../class/classes.js';

/*------------------------------CUSTOM-HOOKS------------------------------*/

import useCalculateTotal from '../../hooks/planTotal';
import useMealRequest from '../../hooks/singleMealRequest';
import useMealActions from '../../hooks/mealActions.js';

/*-------------------------------COMPONENTS-------------------------------*/

import ActionBtn from '../../components/ActionBtn/ActionBtn';

import DefaultPage from '../default-page/DefaultPage.js';

import BoxGroup from '../../components/Sections/BoxGroup/BoxGroup';
import BoxHeader from '../../components/Sections/BoxHeader/BoxHeader';

import PlanDescription from '../../components/Sections/PlanDescription/PlanDescription.js';
import BasicTable from '../../components/BasicTable/BasicTable.js'


//const baseUrl = process.env.REACT_APP_SERVER;

const SingleMealPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  let id = params?.id ? params.id : false;

  const { getSingleMeal, editSingleMeal, deleteSingleMeal } = useMealRequest();
  const actions = useMealActions();

  const userId = useSelector((state) => state.appReducer.id);

  const meal = useSelector((state) => state.appData.currentMeal);
  const editMode = useSelector((state) => state.pageState.editMode);
  const defaultMeal = initialState.currentMeal;

  const [ allSums, totalCount ] = useCalculateTotal([{...meal}]);
  

  useEffect(()=>{
    return ()=>{
      dispatch(resetMealAction());
    };
  }, []);

   useEffect(() => {

    // if meal exists load it
    if (userId && id) {

      dispatch(setLoading(true));
	  
	    getSingleMeal(id).then(()=>{
        dispatch(setLoading(false));
      });

    // if meal not exists create it

    } else if (userId && !id) {
      let newMeal = new Meal({
        name : 'Nuovo pasto'
      });

      newMeal = newMeal.createObj();
      
      dispatch(setEditMode({ editMode: true }));
      dispatch( setSingleMeal(newMeal) );
      dispatch( mealToCreate({mealCode:newMeal.code}) );
    }

    return () => {
      dispatch(setSingleMeal(defaultMeal));
	    dispatch(setEditMode({ editMode: false }));
	  
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]); 


  const handleSave = async () => {
	  
    dispatch(setLoading(true));

    let res = await editSingleMeal();
    
    dispatch(setLoading(false));
    if (!res) return;

    dispatch(resetMealAction());
    dispatch(setEditMode({editMode: !editMode}));
  }

  const handleDiscard = async () => {  
    dispatch(setLoading(true));

    await getSingleMeal(meal.code, false);

    dispatch(setLoading(false));
    
    dispatch(resetMealAction());
    dispatch(setEditMode({editMode: !editMode}));
  };

  const handleDeleteMeal = () =>{
    let message = "Vuoi davvero eliminare: " + meal.name;
    let question = window.confirm(message);
    
    if (question) {
      deleteSingleMeal().then((res)=>{
        if (res) {
          navigate('/home');
        }
      });
    }
  };

  return (
    <>
      <DefaultPage title={'Pasto singolo'} >
        <div className="single-meal-page">

          {
            editMode ?
              <div className="btn-container">
                <div
                  className='btn btn-success'
                  onClick={handleSave}
                >
                  <ActionBtn type='save' />
                </div>
                <div
                  className='btn btn-danger'
                  onClick={handleDiscard}
                >
                  <ActionBtn type='discard' />
                </div>
              </div>
              :

              <div
                className='btn edit-plans-btn'
                onClick={() => { dispatch(setEditMode({ editMode: !editMode })) }}
              >
                <ActionBtn type='edit' />
              </div>
          }

          {meal.code !== '' && <BoxHeader />}

          <BoxGroup data={{meals : [meal]}} allSums={allSums} actions={actions}/>

          {
            (editMode) &&

            <div className="delete-plan-section data-box-width">
              <button className="btn btn-danger-full" onClick={handleDeleteMeal}>Elimina pasto</button>
            </div>
          }

          <PlanDescription data={totalCount} />

          {
            Object.keys(totalCount).length > 0 &&

            <div className="page-container flex-center">
              <BasicTable data={totalCount}/>
            </div>
          }
        </div>
      </DefaultPage>
    </>
  )


}

export default SingleMealPage