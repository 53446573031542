import React from 'react';
import './Graphic.scss';


import { Link } from 'react-router-dom';

import HeaderSection from '../HeaderSection/HeaderSection';

import graph from '../../../img/pie_chart.png';

const Graphic = () => {
  return (
    <section className='graphic-container'>
      <HeaderSection text={'Visualizza info sugli alimenti'}/>
      <Link className='img-container' to='/searchFood'>
        <img src={graph} alt="Link a cerca alimenti"/>
      </Link>
    </section>
  )
}

export default Graphic