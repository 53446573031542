import { useDispatch, useSelector } from 'react-redux'

import { setMealName, createMeal, updateMeals } from '../redux/actions/appData';

// Array of meal code, need it for request to server
import { mealToCreate, mealToUpdate, mealToDelete } from '../redux/actions/appData';
import { insertFood, setFoods, setFoodQuantity } from '../redux/actions/appData';


/*----------------------------IMPORT-CLASSES-----------------------------*/

import {Meal} from '../class/classes';

export default function usePlanActions(){
	
	const dispatch = useDispatch();
	
	const plan = useSelector((state) => state.appData.currentPlan);

	
	const editMealName = (mealCode, value) => {

		if (value === '') return;

		const payload = {
			mealCode,
			value
		};

		dispatch(setMealName(payload));
	};

	const createNewMeal = () => {
		let order = 1;

        if ( plan.meals.length > 0 ) {
            let lastElem = plan.meals.length - 1;
            order = plan.meals[lastElem].order + 1;
        }

        let newMeal = new Meal({
            name : 'Nuovo pasto',
            foods : [],
            order
        });

        dispatch(mealToCreate({ mealCode: newMeal.code }));

        newMeal = newMeal.createObj();

        dispatch( createMeal(newMeal) );
	};

	const deleteMeal = (mealCode) => {

		let tmp = plan.meals.filter((meal) => meal.code !== mealCode);

		dispatch(updateMeals({ meals: tmp }));
		dispatch(mealToDelete({ mealCode }));
	};

	const insertNewFood = (food, mealCode) => {

		dispatch(insertFood({
		  mealCode,
		  food
		}));
	
		dispatch(mealToUpdate({mealCode}));
	
	  }

	const setFood = (mealIndex, foodIndex, quantity) => {
		const payload = {
            mealInd : mealIndex,
            foodInd :foodIndex,
            value : quantity
        }

        let meal = {
            mealCode : plan.meals[mealIndex].code
        }

        dispatch( mealToUpdate(meal) );
        dispatch( setFoodQuantity(payload) );
	};

	const deleteFood = (mealIndex, foodIndex) => {
		let tmp = [
            ...plan.meals[mealIndex].foods
        ]
        tmp.splice(foodIndex, 1);

        let payload = {
            foods : tmp,
            mealInd : mealIndex
        }

        let meal = {
            mealCode : plan.meals[mealIndex].code
        }

        dispatch( mealToUpdate(meal) );

        dispatch( setFoods(payload) );
	};

	
    return {editMealName, createNewMeal, deleteMeal, insertNewFood, setFood, deleteFood}
}