import { useDispatch, useSelector } from 'react-redux'

import { setPlan, setList } from '../redux/actions/appData';
import { fetchData } from '../module/connectionFunctions';

import { IdGenerator } from '../module/library';

/*----------------------------IMPORT-CLASSES-----------------------------*/

import {Rewrite} from '../class/classes';

export default function usePlanRequest(){
	
	const dispatch = useDispatch();
    const token = useSelector((state) => state.appReducer.headers.authorizzation);
	const userId = useSelector((state) => state.appReducer.id);
	
	const plan = useSelector((state) => state.appData.currentPlan);
	const mealsToEdit = useSelector((state) => state.appData.editMeals);

	
	const getPlan = async (planId, cache=true) => {
		
		let res = false;

		let request = 'plan?userId=' + userId + '&planId=' + planId;

		let r = {
			url : request,
			auth : token,
		};

		if (!cache) {
			r.cache = cache
		}
		
		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		if (res.data) {

		  let newPlan = Rewrite.createPlan(res.data[planId]);
		  
		  dispatch(setPlan(newPlan));

		} else {
			console.log(res.message);
		}
	}

	const getPlanList = async (cache=true) => {
		let res = false;
        let request = 'allPlans?userId=' + userId;  

		let r = {
			url : request,
			auth : token,
		};

		if (!cache) {
			r.cache = cache
		}
        
		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

        if (res.data) {

            let tmp = res.data.map((plan)=>{

                return {
                    code: plan.plan_code,
                    name: plan.plan_name
                }
            });

            dispatch(setList({plans:tmp}));
        } else {
            return [];
        }
    };

    const getSingleMealList = async (cache=true) => {

		let res = false;
        let request = 'meals?userId=' + userId; 

		let r = {
			url : request,
			auth : token,
		};

		if (!cache) {
			r.cache = cache
		}

		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}		
        
        if (res.data) {

            let tmp = res.data.map((meal)=>{

                return {
                    code: meal.meal_code,
                    name: meal.meal_name
                }
            });
			
            dispatch(setList({singleMeals:tmp}));
        } else {
			return [];
		}
        
    }
	
	const createPlan = async (planName) => {
		
		let res = false;

		let request = 'plan?userId=' + userId;
		
		let body = {
			plan_code : IdGenerator(4),
			plan_name : planName
		};

		let r = {
			requestType : 'POST',
			url : request,
			data : body,
			auth : token,
		};
		
		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		return res;
	}

	const deletePlan = async () => {
		
		let res = false;

		let request = 'plan?userId=' + userId + '&planId=' + plan.code;
		
		let r = {
			requestType : 'DELETE',
			url : request,
			auth : token,
		};

		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		return res;
	}

	const editPlan = async () => {
		
		let res = false;

		let request = 'plan?userId=' + userId + '&planId=' + plan.code;
		
		let body = {
			plan_name : plan.name
		};

		let r = {
			requestType : 'PUT',
			url : request,
			data : body,
			auth : token,
		};
		
		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		if (res.data) {
		  dispatch(setPlan({updated:false}));
		  await getPlan(plan.code, false);

		} else {
			console.log(res.message);
		}
	}
	
	const editMeals = async () => {
		
		let meals = plan.meals.length > 0 ? plan.meals : [];
		
		let requests = [];

		// MANAGE CREATION AND UPDATE OF PLAN MEALS
		
		for ( let meal of meals ) {

			if ( mealsToEdit.create.includes(meal.code) ) {

				let r = 'plan/singleMeal?userId=' + userId + '&planId=' + plan.code;

				let foods = meal.foods.map((food)=> {
					const {id, quantity} = food;

					return {id, quantity};
				});
				let body = {
					meal_code : meal.code,
					meal_name : meal.name,
					foods
				};

				r = fetchData({
					requestType: 'POST',
					url: r,
					data : body,
					auth: token,
				});

				requests.push(r);
			}
			
			if ( mealsToEdit.update.includes(meal.code) ) {

				let r = 'plan/meal?userId=' + userId + '&mealId=' + meal.code;

				let foods = meal.foods.map((food)=> {
					const {id, quantity} = food;

					return {id, quantity};
				});
				let body = {
					meal_code : meal.code,
					meal_name : meal.name,
					foods
				};

				

				r = fetchData({
					requestType: 'PUT',
					url: r,
					data : body,
					auth: token,
				});

				requests.push(r);
			}
		}

		// MANAGEMENT OF MEALS TO DELETE

		for (let code of mealsToEdit.delete) {

			let r = 'plan/meal?userId=' + userId + '&mealId=' + code;

			
			r = fetchData({
				requestType: 'DELETE',
				url: r,
				auth: token,
			});

			requests.push(r);

		}

		if (requests.length === 0) return;
		
		
		await Promise.all(requests).then((res)=>{

			getPlan(plan.code, false);
		}
		).catch((err)=>{
			console.log(err);
		});
		

	}

	/*------------------------------------FOODS------------------------------------*/

	const getFoods = async (name, cache=true) => {
		
		let res = false;

		if (name === '') return;

		let request = 'foods?userId=' + userId + '&name=' + name;

		let r = {
			url : request,
			auth : token,
		};

		if (!cache) {
			r.cache = cache
		}

		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
		}

		if (res.data) {

			let r = Rewrite.createFood(res.data);

		  return r;

		} else {
			return false;
		}
	}


    return {getPlan, getPlanList, getSingleMealList, createPlan, editPlan, deletePlan, editMeals, getFoods}
}