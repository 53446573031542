import { createSlice } from '@reduxjs/toolkit'

const initialState = {

  isOpenNavbar : false,
  modalMessage : '',
  editMode : false,
  loading : false,
};

export const pageState = createSlice({
  name: 'pageState',
  initialState: initialState,
  reducers: {
    
    setNavbar: (state, action) => {

      state.isOpenNavbar = action.payload.isOpenNavbar;
    },
	
	  setEditMode: (state, action) => {

      state.editMode = action.payload.editMode;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setModalMessage: (state, action) => {
      state.modalMessage = action.payload;
    },

    resetPageState: (state) => {

      state.isOpenNavbar = false;
      state.modalMessage = '';
      state.editMode = false;
      state.loading = false;
    },
    
  },
})

export const { setNavbar, setEditMode, setLoading, setModalMessage, resetPageState } = pageState.actions

export default pageState.reducer