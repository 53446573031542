import { useDispatch, useSelector } from 'react-redux'

import { setList } from '../redux/actions/appData';
import { fetchData } from '../module/connectionFunctions';

/*----------------------------IMPORT-CLASSES-----------------------------*/

import {Rewrite} from '../class/classes';

export default function useFoodRequest(){
	
	const dispatch = useDispatch();
    const token = useSelector((state) => state.appReducer.headers.authorizzation);
	const userId = useSelector((state) => state.appReducer.id);


	const getCustomFoodList = async (cache=true) => {

		let res = false;

        let request = 'allCustomFoods?userId=' + userId;  
        
		try {
			let r = {
                url : request,
                auth : token,
            };

			if (!cache) {
				r.cache = cache
			}

			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

        if (res.data) {

            let tmp = res.data.map((food)=>{

                return {
                    code: food.id,
                    name: food.foodName
                }
            });
            dispatch(setList({customFoods:tmp}));
        } else {
			dispatch(setList({customFoods:[]}));
            //return [];
        }
    };
	
	const createCustomFood = async (data) => {
		
		let res = false;

		let request = 'food?userId=' + userId;
		
		try {
			let r = {
                requestType : 'POST',
                url : request,
                data,
                auth : token,
            };

			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		if (res.data) {
			return true;
		} else return false;

		//return res;
	}

	const deleteCustomFood = async (foodId) => {
		
		let res = false;

		let request = 'food?userId=' + userId + '&foodId=' + foodId;		
		
		try {
			let r = {
                requestType : 'DELETE',
                url : request,
                auth : token,
            };
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		return res;
	}

	/*------------------------------------FOODS------------------------------------*/

	const getFoods = async (name, cache = true) => {

		let res = false;

		if (name === '') return;

		let request = 'foods?userId=' + userId + '&name=' + name;

		try {

			let r = {
				url: request,
				auth: token,
			};

			if (!cache) {
				r.cache = cache
			}

			res = await fetchData(r);
		} catch (err) {
			res = false;
		}

		if (res.data) {

			let r = Rewrite.createFood(res.data);

			return r;

		} else {
			return false;
		}
	}


    return {getFoods, getCustomFoodList, createCustomFood, deleteCustomFood}
}