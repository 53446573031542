import { useDispatch, useSelector } from 'react-redux'

import { setSingleMeal, setSMFood } from '../redux/actions/appData';
import { initialState } from '../redux/actions/appData';

// Array of meal code, need it for request to server
import { mealToUpdate } from '../redux/actions/appData';

export default function useMealActions(){
	
	const dispatch = useDispatch();
	
	const meal = useSelector((state) => state.appData.currentMeal);

	
	const editMealName = (mealCode, value) => {

		if (value === '') return;

		const payload = {
			name : value
		};

		dispatch(setSingleMeal(payload));
        dispatch( mealToUpdate({mealCode}) );
	};

    const deleteMeal = (mealCode) => {
        dispatch(setSingleMeal(initialState.currentMeal));
    };

    const insertNewFood = (food, mealCode) => {

        let payload = {
            foods : [
                ...meal.foods,
                food
            ]
        };

        dispatch(setSingleMeal(payload));
      
        dispatch( mealToUpdate({mealCode : meal.code}) );
    };

    const setFood = (mealIndex, foodIndex, quantity) => {
        let payload = {
            foodIndex,
            quantity
        };

        dispatch( mealToUpdate({mealCode : meal.code}) );
        dispatch(setSMFood(payload));
    };

    const deleteFood = (mealIndex, foodIndex) => {
        let tmp = [
            ...meal.foods
        ]
        tmp.splice(foodIndex, 1);

        let payload = {
            foods : tmp,
        }

        dispatch( mealToUpdate({mealCode : meal.code}) );

        dispatch( setSingleMeal(payload) );
    };

	

	
    return {editMealName, deleteMeal, insertNewFood, setFood, deleteFood }
}