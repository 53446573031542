import React from 'react'

import './Loading.scss';

import { useSelector } from 'react-redux';

const Loading = () => {

  let isLoading = useSelector((state) => state.pageState.loading);

  return (
    <>
      {
        isLoading && <div className='modal-loading' >
          <section className="load-container">
            <div className='loading'>
            </div>
          </section>
        </div>
      }
    </>
  )
}

export default Loading