import React from 'react';
import './BoxGroup.scss';

import { useSelector} from 'react-redux';

/*---------------------------IMPORT-COMPONENTS---------------------------*/

import DataBox from '../../DataBox/DataBox';
import BoxEdit from '../BoxEdit/BoxEdit';

const defaultProps = {
    actions : {
        editMealName : (mealCode, value)=>{}, 
        createNewMeal : ()=>{}, 
        deleteMeal : (mealCode)=>{}, 
        insertNewFood : (food, mealCode)=>{}, 
        setFood : (mealIndex, foodIndex, quantity)=>{}, 
        deleteFood : (mealIndex, foodIndex)=>{}
    }
}

const BoxGroup = ({data, allSums, actions = defaultProps.actions}) => {
	
	const editMode = useSelector((state) => state.pageState.editMode);

    const { editMealName, deleteMeal, insertNewFood, setFood, deleteFood } = actions ;


    const boxList = () => {
        return data.meals.map((meal, index)=>{
            
            const { code, name, order, foods } = meal;

            let sum = {};

            if ( code in allSums ) {
                sum = allSums[code];
            }

            return (
                <DataBox title={name} key={index} totalMacro={sum} >
                    <ul>
                        {
                            foods ? foods.map((food, index)=>{

                                const { quantity, name } = food;

                                return <li key={index}>
                                    <span className="food-name">
                                        {name + ':'}
                                    </span>
                                    <span className="food-quantity">
                                        {quantity + ' g'}
                                    </span>
                                </li>
                            }) 
                            : <li>Nessun pasto da mostrare</li>
                        }
                    </ul>
                </DataBox>
            );
        });
    };

    const editBoxList = () => {

        const BoxContainer = <>
            {
                data.meals.map((meal, mealIndex) => {

                    const { code, name, order, foods } = meal;

                    let sum = {};

                    if (code in allSums) {
                        sum = allSums[code];
                    }
                    
                    return (
                        <DataBox title={name} mealCode={code} key={mealIndex} totalMacro={sum} renameBox={editMealName} deleteBox={deleteMeal} addElemList={insertNewFood} >
                            <div className='forms-contaier'>
                                {
                                    foods ? foods.map((food, foodIndex) => {

                                        const indexes = {
                                            mealIndex,
                                            foodIndex
                                        }

                                        return <BoxEdit food={food} key={foodIndex} indexes={indexes}  actionEdit={setFood} actionRemove={deleteFood}/>
                                    })
                                        : <li>Nessun pasto da mostrare</li>
                                }
                            </div>
                        </DataBox>
                    );
                })
            }
        </>

        return BoxContainer;
    };

  return (
    <div className="box-group">
        { ( data && data?.meals && data.meals.length > 0 && allSums) ? 
            editMode ? editBoxList() :  boxList() 
            : <span className='message'>Nessun pasto presente</span>
        }
    </div>
  )
}

export default BoxGroup