import { useMemo } from 'react';

export default function useCalculateTotal(meals) {

  const calculateTot = useMemo(() => {
    
    if (meals.length !== 0) {

     // let meals = plan.meals;

      let planSum = {};
      let total = {};

      for (let meal of meals) {

        let tot = {};
        
        for (let food of meal.foods) {

          for (let key in food?.details) {

            if (key != 'id' && key != 'foodName' && key != 'ediblePart') {

              // calculate by custom quantity and relative macro for 100g

              let macro = food.details[key].quantity * food.quantity / 100;

              // calculate RELATIVE tot

              if (key in tot) {
 
                let op = tot[key].quantity + macro;
                tot[key] = {
                  displayedName : food.details[key].displayedName,
                  quantity : Number(op.toFixed(2))
                }

              } else {
                tot = {
                  ...tot,
                  [key]: {
                    displayedName : food.details[key].displayedName,
                    quantity : Number(macro.toFixed(2))
                  }
                };

              }

              // calculate ABSOLUTE total

              if (key in total) {

                let op = total[key].quantity + macro;

                total[key] = {
                  displayedName : food.details[key].displayedName,
                  quantity : Number(op.toFixed(2))
                }

              } else {
                total = {
                  ...total,
                  [key]: {
                    displayedName : food.details[key].displayedName,
                    quantity : Number(macro.toFixed(2))
                  }
                };
              }
            }
          }
        }

        planSum = {
          ...planSum,
          [meal.code]: tot
        };

      }
      return {
        planSum,
        total
      };

    }
    return {
      planSum : {},
        total : {}
    }
  }, [meals]);

  return [calculateTot.planSum , calculateTot.total];
}