import { createSlice } from '@reduxjs/toolkit'

export const initialState = {

  currentPlan: {
    code: '',
    name: 'Plan',
    creation: '',
    meals: [],
    updated: false
  },
  currentMeal: {
    code: '',
    name: 'Meal',
    foods: [],
    order: 0
  },

  editMeals: {
    create: [],
    update: [],
    delete: []
  },

  list: {
    plans: [],
    singleMeals: [],
    customFoods : []
  }

};

export const appData = createSlice({
  name: 'appData',
  initialState: initialState,
  reducers: {

    setList: (state, action) => {

      state.list = {
        ...state.list,
        ...action.payload
      }
    },
    /*------------------------------SINGLE-MEAL-MANAGEMENT-----------------------------*/

    setSingleMeal: (state, action) => {

      state.currentMeal = {
        ...state.currentMeal,
        ...action.payload
      };
    },

    // SET FOOD

    setSMFood: (state, action) => {

      let index = action.payload.foodIndex;
      let quantity = action.payload.quantity;

      state.currentMeal.foods[index].quantity = quantity;
    },

    /*---------------------------------PLAN-MANAGEMENT---------------------------------*/

    setPlan: (state, action) => {

      state.currentPlan = {
        ...state.currentPlan,
        ...action.payload
      };
    },

    /*---------------------------------FOOD-MANAGEMENT---------------------------------*/

    insertFood: (state, action) => {
      let mealCode = action.payload.mealCode;
      let food = action.payload.food;

      let tmp = state.currentPlan.meals.map((meal) => {
        if (meal.code === mealCode) {
          let newFoods = [
            ...meal.foods,
            food
          ];

          return {
            ...meal,
            foods: newFoods
          }
        }
        return meal
      });

      state.currentPlan.meals = tmp;
    },

    setFoodQuantity: (state, action) => {

      let mealInd = action.payload.mealInd;
      let foodInd = action.payload.foodInd;
      let value = action.payload.value;

      state.currentPlan.meals[mealInd].foods[foodInd].quantity = value;
    },

    setFoods: (state, action) => {

      let mealInd = action.payload.mealInd;
      let foods = action.payload.foods;

      state.currentPlan.meals[mealInd].foods = foods;
    },

    /*---------------------------------MEAL-MANAGEMENT---------------------------------*/

    createMeal: (state, action) => {

      let meal = action.payload;

      state.currentPlan.meals = [
        ...state.currentPlan.meals,
        meal
      ];
    },

    updateMeals: (state, action) => {

      let meals = action.payload.meals;

      state.currentPlan.meals = meals;
    },

    setMealName: (state, action) => {

      let mealCode = action.payload.mealCode;
      let value = action.payload.value;

      let updateMeals = state.currentPlan.meals.map((meal) => {
        if (meal.code === mealCode) {
          return {
            ...meal,
            name: value
          }
        }
        return meal;
      });

      state.currentPlan.meals = updateMeals;

      let createSet = new Set(state.editMeals.create);

      if (!createSet.has(mealCode)) {

        // Update meal to update
        let updateSet = new Set(state.editMeals.update);
        updateSet.add(mealCode);

        state.editMeals.update = [
          ...updateSet
        ];
      }
    },

    // REQUESTS TO SERVER

    mealToCreate: (state, action) => {

      let meal = action.payload.mealCode;

      let createSet = new Set(state.editMeals.create);
      createSet.add(meal);

      state.editMeals.create = [
        ...createSet
      ];

    },

    mealToUpdate: (state, action) => {

      let meal = action.payload.mealCode;

      let createSet = new Set(state.editMeals.create);

      if (!createSet.has(meal)) {

        let updateSet = new Set(state.editMeals.update);
        updateSet.add(meal);

        state.editMeals.update = [
          ...updateSet
        ];
      }
    },

    mealToDelete: (state, action) => {

      let meal = action.payload.mealCode;

      let deleteSet = new Set(state.editMeals.delete);

      let createSet = new Set(state.editMeals.create);
      let updateSet = new Set(state.editMeals.update);

      if (updateSet.has(meal)) {
        updateSet.delete(meal);

        state.editMeals.update = [
          ...updateSet
        ];
      }

      if (!createSet.has(meal)) {
        deleteSet.add(meal);

        state.editMeals.delete = [
          ...deleteSet
        ];
      }

      if (createSet.has(meal)) {
        createSet.delete(meal);

        state.editMeals.create = [
          ...createSet
        ];
      }

    },

    resetMealAction: (state) => {

      state.editMeals.create = [];
      state.editMeals.update = [];
      state.editMeals.delete = [];

    },

    resetAppData: (state) => {

      state.currentPlan = {
        code: '',
        name: 'Plan',
        creation: '',
        meals: [],
        updated: false
      };
      state.currentMeal = {
        code: '',
        name: 'Meal',
        foods: [],
        order: 0
      };

      state.editMeals = {
        create: [],
        update: [],
        delete: []
      };

      state.list = {
        plans: [],
        singleMeals: [],
        customFoods: []
      };

    },
  },
})

export const { setList, setSingleMeal, setSMFood, setPlan, setSinlgeMeals, insertFood, setFoodQuantity, setFoods, mealToCreate, mealToUpdate, mealToDelete, resetMealAction, setMealName, createMeal, updateMeals, resetAppData } = appData.actions

export default appData.reducer