import React, {useEffect} from 'react';
import './Plans.scss';

import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'

import { setList } from '../../../redux/actions/appData';
import { setLoading } from '../../../redux/actions/pageState';

/*------------------------------CUSTOM-HOOKS------------------------------*/

import usePlanRequest from '../../../hooks/planRequest';

/*----------------------------IMPORT-COMPONENTS---------------------------*/

import HeaderSection from '../HeaderSection/HeaderSection';

//const baseUrl = process.env.REACT_APP_SERVER;

const Plans = () => {

    const { plans, singleMeals } = useSelector((state) => state.appData.list);
    	
	const { getPlanList, getSingleMealList } = usePlanRequest();

	const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{

        dispatch(setLoading(true));
        
        getData().then(()=>{
            dispatch(setLoading(false));
        });

        async function getData () {
            await getPlanList();
            await getSingleMealList();
        }
		
		return ()=>{
			//Reset list 
			dispatch(setList({plans:[], singleMeals:[]}));
		};
    }, []);


    const seePlans = ()=>{

        if (!plans || plans.length === 0) {
            return <li>Nessun piano presente</li>;
        }

        return plans.map((plan, index)=>{
            return <li 
                        key={index}
                        onClick={()=>{navigate('/plan/' + plan.code);}}
                    >
                        {plan.name}
                    </li>
        });
    };

    const seeMeals = ()=>{

        if (!singleMeals || singleMeals.length === 0) {
            return <li>Nessun pasto presente</li>;
        }

        return singleMeals.map((meal, index)=>{
            return <li 
                        key={index}
                        onClick={()=>{navigate('/meal/' + meal.code);}}
                    >
                        {meal.name}
                    </li>
        });
    };

  return (
    <section className="plans">
        <h1>I miei piani</h1>

        <section className="section">
            <HeaderSection text='Piani Giornalieri' color='#E32B2B'/>
            <ul>
                {seePlans()}
            </ul>
        </section>
        <section className="section">
            <HeaderSection text='Pasti singoli' color='#E32B2B'/>
            <ul>
                {seeMeals()}
            </ul>
        </section>
    </section>
  )
}

export default Plans