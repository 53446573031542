import React, {useEffect, useState} from 'react';
import './SettingPage.scss';
import { useNavigate } from 'react-router-dom';

/*----------------------------------HOOKS-------------------------------*/

import useUser from '../../hooks/user';
import useFoodActions from '../../hooks/foodActions';

/*-------------------------------COMPONENTS-------------------------------*/

import DefaultPage from '../default-page/DefaultPage';
import HeaderSection from '../../components/Sections/HeaderSection/HeaderSection';
import ActionBtn from '../../components/ActionBtn/ActionBtn';

/*---------------------------------STATE----------------------------------*/
import { useSelector, useDispatch } from 'react-redux';
import { setModalMessage } from '../../redux/actions/pageState';
import { setList } from '../../redux/actions/appData';

const initialPassword = {
    oldPwd : '',
    newPwd : '',
    rePwd : '',
};


const ProfilePage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { changePassword } = useUser();
    const { getFoods, deleteFood } = useFoodActions();

    const foodList = useSelector((state)=> state.appData.list.customFoods);

    const [ formPassword, setFormPassword ] = useState(initialPassword);
    const [ errMessage, setErrMessage ] = useState('');

    // Custom Food Functions


    useEffect(()=>{

        // get food list
        getFoods();

        // reset custom foods list
        return ()=>{
            dispatch( setList({ customFoods : [] }) );
        }
    }, []);

    const handleDeleteFood = (id) => {
        let confirm = window.confirm('Sei sicuro?');
        if ( confirm ) {
            deleteFood(id);
        }
        
    }; 


    // Change User Password Functions

    const handleChangePassword = (e) => {
        let { name, value } = e.target;

        if ( name === 'rePwd' ) {
            if ( formPassword.newPwd !== value ) {
                setErrMessage('Le password non coincidono');
            } else {
                setErrMessage('');
            }
        }

        setFormPassword((prevState)=>{
            return {
                ...prevState,
                [name] : value
            };
        });
    };

    const handleSubmitPassword = async (e) => {
        e.preventDefault();

        if ( !formPassword.oldPwd || !formPassword.newPwd || !formPassword.rePwd) return;

        if ( formPassword.newPwd !== formPassword.rePwd ) {
            dispatch(setModalMessage('Le password non coincidono'));
            return;
        }

        let body = {
            password : formPassword.oldPwd,
            newPassword : formPassword.newPwd
        }

        let res = await changePassword(body);

        if (res.data) {
            dispatch(setModalMessage('Password modificata'));
            setFormPassword(initialPassword);
        } else if (res.message) {

            if ( res.message.toLowerCase() === 'wrong password' ) {
                setErrMessage('La vecchia password non è corretta');
                return;
            }

            setErrMessage(res.message);
        }
    };

    const writeFoodList = () => {

        return foodList.map((food, index) => {
            return <div className="row" key={index}>
                <li>
                    {food.name}
                </li>
                <ActionBtn type='remove' action={() => { handleDeleteFood(food.code) }} />
            </div>
        })
    };

  return (
    <DefaultPage title='Impostazioni'>
        <div className='page-container setting-page'>
            <div className="page-data">
                <HeaderSection text='Cambio password:' />
                <form onSubmit={handleSubmitPassword}>
                    <div className="row">
                        <label htmlFor="oldPwd">Vecchia password</label>
                        <input type="password" name="oldPwd" id="oldPwd" className='input' autoComplete='on' onChange={handleChangePassword}/>
                    </div>
                    <div className="row">
                        <label htmlFor="newPwd">Nuova password</label>
                        <input type="password" name="newPwd" id="newPwd" className='input' autoComplete='on' onChange={handleChangePassword}/>
                    </div>
                    <div className="row">
                        <label htmlFor="rePwd">Reinserisci password</label>
                        <input type="password" name="rePwd" id="rePwd" className='input' autoComplete='on' onChange={handleChangePassword}/>
                    </div>

                    { errMessage && <span className="err-message">{errMessage}</span> }
                    <button className="btn btn-success">Salva</button>
                </form>
            </div>
            <div className="page-data">
                <HeaderSection text='Alimenti personalizzati:' />
                <ul className='food-list-container'>
                    {
                        foodList.length > 0 && writeFoodList()
                    }
                    <ActionBtn type='add' cssClass='cl-primary' action={()=>{navigate('/addFood')}}/>
                </ul>

            </div>
        </div>
    </DefaultPage>
  )
}

export default ProfilePage