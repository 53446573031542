import React from 'react';
import './BoxHeader.scss';

import DivideLine from '../../DivideLine/DivideLine';

const BoxHeader = ({cssClass = ''}) => {

  return (
    <header className={`boxes-header ${cssClass}`}>

		<div className="desc-container">
			<div className='desc'>
				<span>Kcal</span>
				<DivideLine height='70%' />
			</div>
			
			<div className='desc'>
				<span>Carbo</span>
				<DivideLine height='70%' />
			</div>

			<div className='desc'>
				<span>Prot</span>
				<DivideLine height='70%' />
			</div>

			<div className='desc'>
				<span>Fat</span>
			</div>
		</div>

		
	</header>
  )
}

export default BoxHeader