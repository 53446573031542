import React, { useEffect, useState } from 'react';
import '../HeaderSection/HeaderSection.scss';
import './HeaderSectionEdit.scss';

const defaultProps = {
  text: 'text',
  action: ()=>{},
  cssClass: '',
};

const HeaderSectionEdit = ({ 
  text = defaultProps.text,
  action = defaultProps.action,
  cssClass = defaultProps.cssClass
}) => {

  const [field, setField] = useState(text);

  useEffect(()=>{
    setField(text);
  }, [text]);

  const handleChange = (e) => {
    let value = e.target.value;

    setField(value);
  }

  const handleBlur = (value) => {

    if (value === '') {
      setField(text);
    }
    action(value);
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    //handleBlur(e.target.fieldName.value);

    e.target.fieldName.blur();
  }


  return (
    <form className={`header-section head-edit ${cssClass}`} onSubmit={handleSubmit}>
      <input
        type="text"
        name='fieldName'
        id='fieldName'
        autoComplete='off'
        value={field}
        onChange={handleChange}
        onBlur={(e)=>{handleBlur(e.target.value)}}
      />
      <div className="line"></div>
    </form>
  )


}

export default HeaderSectionEdit