import { useDispatch, useSelector } from 'react-redux'

import { setSingleMeal, setList } from '../redux/actions/appData';
import { fetchData } from '../module/connectionFunctions';

import { IdGenerator } from '../module/library';

/*----------------------------IMPORT-CLASSES-----------------------------*/

import {Rewrite} from '../class/classes';

export default function useMealRequest(){
	
	const dispatch = useDispatch();
    const token = useSelector((state) => state.appReducer.headers.authorizzation);
	const userId = useSelector((state) => state.appReducer.id);
	
	const meal = useSelector((state) => state.appData.currentMeal);
	const mealsToEdit = useSelector((state) => state.appData.editMeals);

	
	const getSingleMeal = async (mealId, cache=true) => {
		
		let res = false;

		let request = 'meal/' + mealId + '?userId=' + userId;

		let r = {
			url : request,
			auth : token,
		};

		if (!cache) {
			r.cache = cache
		}
		
		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		if (res.data) {

		  let newMeal = Rewrite.createMeal([res.data]);
		  newMeal = newMeal[0];
		  
		  dispatch(setSingleMeal(newMeal));

		} else {
			console.log(res.message);
		}
	}


    const getSingleMealList = async (cache=true) => {

		let res = false;
        let request = 'meals?userId=' + userId; 

		let r = {
			url : request,
			auth : token,
		};

		if (!cache) {
			r.cache = cache
		}

		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}		
        
        if (res.data) {

            let tmp = res.data.map((meal)=>{

                return {
                    code: meal.meal_code,
                    name: meal.meal_name
                }
            });
			
            dispatch(setList({singleMeals:tmp}));
        } else {
			return [];
		}
        
    }
	
	const createSingleMeal = async () => {
		
		let res = false;

		let request = 'meal?userId=' + userId;
		
		let body = {
			meal_code : meal.code,
			meal_name : meal.name,
			foods : meal.foods
		};

		let r = {
			requestType : 'POST',
			url : request,
			data : body,
			auth : token,
		};
		
		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		if (res.data) {
			return true;
		}

		return false;
	}

	const editSingleMeal = async () => {

		if (meal.foods.length < 1) {
			alert('Il pasto non può essere vuoto');
			return false;
		}

		if ( mealsToEdit.create.includes(meal.code) ) {
			return await createSingleMeal();
		}

		if ( mealsToEdit.update.includes(meal.code) ) {

			let res = false;

			let request = 'meal?userId=' + userId + '&mealId=' + meal.code;

			let foods = meal.foods.map((food)=> {
				const {id, quantity} = food;

				return {id, quantity};
			});
			let body = {
				meal_name : meal.name,
				foods
			};

			let r = {
                requestType : 'PUT',
                url : request,
                data : body,
                auth : token,
            };

			try {
				res = await fetchData(r);
			} catch (err) {
				res = false;
				console.log(err);
			}		
			
			if (res.data) {
	
				getSingleMeal(meal.code, false);
				return true;
			} else {
				return false;
			}
		}
	};

	const deleteSingleMeal = async () => {
		let res = false;

		let request = 'meal?userId=' + userId + '&mealId=' + meal.code;

		let r = {
			requestType : 'DELETE',
			url : request,
			auth : token,
		};
		
		try {
			res = await fetchData(r);
		} catch (err) {
			res = false;
            console.log(err);
		}

		if (res.data) {

		  return true;

		} else {
			return false;
		}
	};

    return {getSingleMeal, getSingleMealList, createSingleMeal, editSingleMeal, deleteSingleMeal}
}