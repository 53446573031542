import React, { useState, useEffect } from 'react';

import '../form.scss';
import './UserData.scss';

import { MdOutlineArrowBackIos } from "react-icons/md";

import { useDispatch, useSelector } from 'react-redux';
import { registration, setPage } from '../../../redux/actions/appSlice';

import useUser from '../../../hooks/user';

const UserData = ({action}) => {

  const dispatch = useDispatch();

  const { signup } = useUser();

  const registrationStep = useSelector((state) => state.appReducer.registration);
  const step = useSelector((state) => state.appReducer.registration.step2);

  const [ message, setMessage ] = useState( "" );

  const [ form, setForm ] = useState({
    "name" : "",
    "surname" : "",
    "birth" : "",
    "weight" : ""
  });

  useEffect(() => {
    if (step) {
      setForm(
        {
          "name" : step.name,
          "surname" : step.surname,
          "birth" : step.birth,
          "weight" : step.weight
        }
      );
    }
  }, [step]);

  const [ checkField, setCheckField ] = useState( {
    "name" : false,
    "surname" : false,
    "birth" : false,
    "weight" : false
  } );

  const checkAllFields = ( form )=> {

    let result = true;

    for ( let field in form ) {

      if ( form[field] === "" ){

        setCheckField( (prevState)=>{
          return {
            ...prevState,
            [field] : true
          }
        } );

        result =  false;
      }

    }

    return result;
  };

  const handleChange = (e) => {

    const name = e.target.name;
    const value = e.target.value;

    setMessage( "" );

    setForm( ( prevState )=>{
      return {
        ...prevState,
        [name] : value
      }
    } );

    if ( value != "" ) {

      setCheckField( (prevState)=>{
        return {
          ...prevState,
          [name] : false
        }
      } );

    }

  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    setMessage( "" );

    if ( checkAllFields(form) ) {

      dispatch( registration( {
        step: "step2",
        data : form
      } ) );

      let data = {
        username : registrationStep.step1.username,
        password : registrationStep.step1.password,
        email : registrationStep.step1.email,
        name : form.name,
        surname : form.surname,
        birth_date : form.birth,
        weight : form.weight,
      };
      
      let res = await signup( data );

      if ( res.data ) {

        action(true);
      } else if ( res.message ) {

        setMessage( res.message );
      }

    } else {

      setMessage( "Compilare correttamente tutti i campi!" );
      return;
    }

  }

  return (
    <form className='form' onSubmit={handleSubmit}>

      <button 
        className='back-icon'
        type='button'
        onClick={()=>{dispatch( setPage({page:1}))}}
      >
          <MdOutlineArrowBackIos/>
      </button>

      <h2>Completa i tuoi dati</h2>

      <div className="field">
        <label className='label' htmlFor="name">Nome</label>
        <input 
          className={ checkField.name ? 'input err-input-border' : 'input' } 
          type="text" 
          name="name" 
          id="name" 
          onChange={handleChange}
          value={ form.name }
          autoComplete='off'
        />
      </div>

      <div className="field">
        <label className='label' htmlFor="surname">Cognome</label>
        <input 
          className={ checkField.surname ? 'input err-input-border' : 'input' } 
          type="text" 
          name="surname" 
          id="surname" 
          onChange={handleChange}
          value={ form.surname }
          autoComplete='off'
        />
      </div>

      <div className="field">
        <label className='label' htmlFor="birth">Data di nascita</label>
        <input 
          className={ checkField.birth ? 'input err-input-border' : 'input' } 
          type='date'
          name="birth" 
          id="birth" 
          onChange={handleChange}
          value={ form.birth }
          autoComplete='off'
        />
      </div>

      <div className="field">
        <label className='label' htmlFor="weight">Peso</label>
        <input 
          className={ checkField.weight ? 'input err-input-border' : 'input' } 
          type="number" 
          name="weight" 
          id="weight" 
          onChange={handleChange}
          value={ form.weight }
          autoComplete='off'
        />
      </div>

      {message && <span className='err-message message'>{message}</span>}

      <button className='btn' type="submit">Registrati</button>
    </form>
  )
}

export default UserData