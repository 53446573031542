import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './HomePage.scss';

import { useDispatch } from 'react-redux';
import { setNavbar } from '../../redux/actions/pageState';

/*------------------------------CUSTOM-HOOKS------------------------------*/

import usePlanRequest from '../../hooks/planRequest';
import useMealRequest from '../../hooks/singleMealRequest';

/*-------------------------------COMPONENTS-------------------------------*/
import DefaultPage from '../default-page/DefaultPage';

import Plans from '../../components/Sections/Plans/Plans';
import DivideLine from '../../components/DivideLine/DivideLine';
import Graphic from '../../components/Sections/Graphic/Graphic';
import ModalChange from '../../components/Sections/ModalChange/ModalChange';
import CreateNew from '../../components/CreateNew/CreateNew';

const HomePage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { createPlan, getPlanList, getSingleMealList } = usePlanRequest();
  const { createSingleMeal } = useMealRequest();
  
  const [ newPlanModal, setNewPlanModal ] = useState(false);
  const [ newMealModal, setNewMealModal ] = useState(false);

  useEffect(()=>{

    let screenWidth = document.body.getBoundingClientRect().width;

    if (!screenWidth) return;

    if (screenWidth > 1200) {
        dispatch(
            setNavbar({isOpenNavbar: true})
        );
        
    } else {
        dispatch(
            setNavbar({isOpenNavbar: false})
        );
    }

},[dispatch]);

	const managePlanModal = ()=> {
		setNewPlanModal((prevState)=>!prevState);
	};

	const manageMealModal = ()=> {
		setNewMealModal((prevState)=>!prevState);
	};

	const handleCreatePlan = async (value) => {
		await createPlan(value);
		await getPlanList(false);
		
	};

	const handleCreateMeal = async (value) => {
		await createSingleMeal(value);
		await getSingleMealList(false);
		
	};
  
  return (
    <>
	  {
		  newPlanModal && 
			<ModalChange
        text='Nuovo piano'
				closeModal={managePlanModal}
				action={handleCreatePlan}
        btnClass='btn-classic-full'
			/>
	  }
	  {
		  newMealModal && 
			<ModalChange
        text='Nuovo pasto'
				closeModal={manageMealModal}
				action={handleCreateMeal}
        btnClass='btn-classic-full'
			/>
	  }
      <DefaultPage title='Home'>
        <section className="home-info">
          <Plans />
          <DivideLine height={'55vw'} width='1px'/>
          <Graphic/>
        </section>

        <div className="divide">
          <DivideLine width={'60%'}/>
        </div>

        <section className="section-50">

          <CreateNew 
            text='Crea nuovo piano'
            action={managePlanModal}
          />

          <CreateNew 
            text='Crea nuovo pasto'
            action={()=>{navigate('/meal/newMeal')}}
          />
          
        </section>
      </DefaultPage>
    </>
  )

  
}

export default HomePage