import React from 'react';
import './ActionBtn.scss';

// import components

import { GrEdit} from "react-icons/gr";
import { MdOutlineCancel } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { IoMdSave, IoIosAddCircleOutline } from "react-icons/io";

const defaultProps = {
	type : 'edit',
	btnType : 'button',
	action : ()=>{},
	cssClass : ''
};

const ActionBtn = ({
	type = defaultProps.type,
	btnType = defaultProps.btnType,
	action = defaultProps.action,
	cssClass = defaultProps.cssClass
}) => {


const btnContent = ()=>{
	
	let res = null;
	
	switch (type) {
		
		case 'edit' : {
			res = (
				<>
					<GrEdit className='icon cl-primary'/>
					<span className='text cl-primary'>modifica</span>
				</>
			);
			break;
		}
		case 'save' : {
			res = (
				<>
					<IoMdSave className='icon cl-success'/>
					<span className='text cl-success'>salva</span>
				</>
			);
			break;
		}
		case 'remove' : {
			res = (
				<>
					<IoClose className='icon cl-danger' style={{fontSize:'1.3em'}}/>
					{/* <span className='text cl-danger'>rimuovi</span> */}
				</>
			);
			break;
		}
		case 'add' : {
			res = (
				<>
					<IoIosAddCircleOutline className='icon'/>
					<span className='text'>aggiungi</span>
				</>
			);
			break;
		}
		case 'discard' : {
			res = (
				<>
					<MdOutlineCancel className='icon cl-danger'/>
					<span className='text cl-danger'>cancella</span>
				</>
			);
			break;
		}
		default: {
			res = (
				<>
					<GrEdit className='icon'/>
					<span className='text'>modifica</span>
				</>
			);
			break;
		}
	}
	
	return res;
};

  if ( btnType === 'submit') {
	return (
		<button 
			className={`action-btn ${cssClass}`}
			type={btnType}
		>
			{btnContent()}
		</button>
	  )
  }

  return (
    <button 
		className={`action-btn ${cssClass}`}
		type={btnType}
		onClick={action}
	>
		{btnContent()}
	</button>
  )
}

export default ActionBtn