import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.scss';

import userDefault from '../../img/user_default.jpg';

/*----------------------------------HOOKS-------------------------------*/

import useUser from '../../hooks/user';

/*-------------------------------USE-LIBRARY-------------------------------*/

import { ageFromBirth } from '../../module/library';

/*-------------------------------COMPONENTS-------------------------------*/

import DefaultPage from '../default-page/DefaultPage';
import HeaderSection from '../../components/Sections/HeaderSection/HeaderSection';
import ActionBtn from '../../components/ActionBtn/ActionBtn';
import ModalChange from '../../components/Sections/ModalChange/ModalChange'

/*---------------------------------STATE----------------------------------*/
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setEditMode, setModalMessage } from '../../redux/actions/pageState';

const defaultInfoForm = {
	name: '',
	surname: '',
	birthDate: '',
	weight: ''
};

const ProfilePage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [img, setImg] = useState(userDefault);
    const [error, setError] = useState('');
	const [ infoForm, setInfoForm ] = useState(defaultInfoForm);
	const [ deleteAccount, setDeleteAccount ] = useState(false);

    const { updateUserInfo, uploadImg, deleteUser } = useUser();

    const user = useSelector((state)=> state.appReducer.user);
    const profileImg = useSelector((state) => state.appReducer.user.profile_img);
	const isEdit = useSelector((state)=> state.pageState.editMode);

    const { name, surname, birth_date, weight, username, email } = user;
    
    const age = ageFromBirth(birth_date);

	// USER DATA FUNTIONS

	useEffect(()=>{
        return ()=>{
			dispatch(setEditMode({editMode:false}));
		};
    },[]);

	useEffect(()=>{
        if (isEdit) {
			setInfoForm({
				name,
				surname,
				birthDate : birth_date,
				weight,
			});
		} else {
			setInfoForm(defaultInfoForm);
		}
    },[isEdit]);

	const handleEditMode = () => {
		dispatch(setEditMode({editMode: !isEdit}));
	};

	const saveUserInfo = async (e) => {
        e.preventDefault();

		const {name, surname, birthDate, weight} = infoForm;

		// Check the values

		if ( name === '' || surname === '' || !birthDate || weight === '' ) {
			alert('I campi non possono essere vuoti');
			return;
		}

		// check date
		
		let birth = new Date(birthDate);
		let today = new Date();

		if ( birth.getTime() >=  today.getTime()) {
			alert('Data non valida');
			return;
		}

		if (weight == 0) {
			alert('Peso non valido');
			return;
		}

		// if "updated" is equal to FALSE, it doesn't send a update request
		let updated = false;

		if ( user.name !== name ||
			user.surname !== surname ||
			user.birth_date !== birthDate ||
			user.weight !== weight ) {
			
			updated = true;
		}
		
		// SEND data to database

		if (updated) {

			let body = {
				name,
				surname,
				birth_date: birthDate,
				weight
			}
	
			dispatch(setLoading(true));
	
			let res = await updateUserInfo(body);
	
			dispatch(setLoading(false));
	
			if (res) {
				dispatch(setModalMessage('Modifica effettuata'));
			}
		}

		handleEditMode();

    };

	const handleChangeInfo = (e) =>{
		let { name, value } = e.target;

		setInfoForm((prevState)=>{
			return {
				...prevState,
				[name] : value,
			}
		});
	};

	// USER IMG FUNCTIONS

    useEffect(()=>{
        if (profileImg) {
            setImg(profileImg);
        }
    },[profileImg]);

    const seeError = (msg) => {

        setError(msg);

        setTimeout(()=>{
            setError('');
        }, 3000);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let form = e.target

        if (!form) return;

        let formData = new FormData(form);

        dispatch(setLoading(true));

        let res = await uploadImg(formData);

        dispatch(setLoading(false));

        if (res?.message) {

           seeError(res.message);
        }

    };

	// MANAGE DELETE ACCOUNT MODAL

	const manageDeleteModal = () => {
		setDeleteAccount((prevState)=> !prevState);
	}

	const handleDeleteUser = async (password) => {

		let confirm = await deleteUser(password);
		if (confirm) {
			dispatch(setModalMessage('Utente eliminato con successo'));
			navigate('/');
		} else {
			alert('Password errata');
		}
	}

	const UserInfo = ()=> {
		
		return(
			<div className="page-data">
				<div className="row">
					<span className='desc'>Nome:</span>
					<span>{name}</span>
				</div>
				<div className="row">
					<span className='desc'>Cognome:</span>
					<span>{surname}</span>
				</div>
				<div className="row">
					<span className='desc'>Età:</span>
					<span>{age}</span>
				</div>
				<div className="row">
					<span className='desc'>Peso kg:</span>
					<span>{weight}</span>
				</div>
			</div>
		);
	};
		
	
	const UserInfoEdit =()=>{ 
	
		return (
			<form className='page-data' >
				<div className="row">
					<span className='desc'>Nome:</span>
					<input 
						className='input'
						name='name'
						id='name'
						type='text'
						onChange={handleChangeInfo}
						value={infoForm.name}
						autoComplete='on'
					/>
				</div>
				<div className="row">
					<span className='desc'>Cognome:</span>
					<input 
						className='input'
						name='surname'
						id='surname'
						type='text'
						onChange={handleChangeInfo}
						value={infoForm.surname}
						autoComplete='on'
					/>
				</div>
				<div className="row">
					<span className='desc'>Età:</span>
					<input 
						className='input'
						name='birthDate'
						id='birthDate'
						type='date'
						onChange={handleChangeInfo}
						value={infoForm.birthDate}
						autoComplete='off'
					/>
				</div>
				<div className="row">
					<span className='desc'>Peso kg:</span>
					<input 
						className='input'
						name='weight'
						id='weight'
						type='number'
						onChange={handleChangeInfo}
						value={infoForm.weight}
						autoComplete='on'
					/>
				</div>
			</form>
		);
	};

  return (
    <DefaultPage title='Profilo'>

		{
			deleteAccount && <ModalChange
								text='Inserisci password'
								inputType='password'
								closeModal={manageDeleteModal}
								action={handleDeleteUser}
								btnClass='btn-danger-full'
							/>
		}

        <div className='page-container'>
			<HeaderSection text='Dati personali:'>
				{
					isEdit ? <ActionBtn type='save' action={saveUserInfo}/> : 
					<ActionBtn type='edit' action={handleEditMode}/>
				}
			</HeaderSection>
            
			{
				isEdit ? UserInfoEdit() : UserInfo()
			}

            <HeaderSection text='Account:'/>
            <div className="page-data">
                <div className="row">
                    <span className='desc'>Username:</span>
                    <span>{username}</span>
                </div>
                <div className="row">
                    <span className='desc'>E-mail:</span>
                    <span>{email}</span>
                </div>
            </div>

            <HeaderSection text='DANGER ZONE:' cssClass='cl-danger' />
            <div className="page-data">
                <div className="row">
                    <button 
						className="btn btn-danger"
						style={{margin:'1em auto'}}
						onClick={manageDeleteModal}
					>Elimina Account</button>
                </div>
            </div>

            <HeaderSection text='Immagine profilo:'/>
            <div className="page-data">
                <div className="img-container">
                    <img src={img} alt="User" />
                </div>
                <form onSubmit={handleSubmit}>
                    <input type="file" name="file" id="file" />
                    <span className="err-message">{error}</span>
                    <button type='submit' className='btn' >Invia</button>
                </form>
            </div>
        </div>
    </DefaultPage>
  )
}

export default ProfilePage