import React from 'react';

import useAuth from '../../hooks/auth';

const LogoutBtn = ({children, cssClass}) => {
	
	const {logout} = useAuth();
	
	const handleClick = async()=>{
		await logout();
	};
	
  return (
    <button 
		className={cssClass} 
		onClick={handleClick}
	>
		{children}
    </button>
  )
}

export default LogoutBtn