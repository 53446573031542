import React, { useEffect, useState } from 'react';
import './DefaultPage.scss';

/*---------------------------------REDUX----------------------------------*/

import { useSelector } from 'react-redux';

/*-------------------------------COMPONENTS-------------------------------*/

import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import Body from '../../components/Sections/Body/Body';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';

const defaultProps = {
  children : (<></>),
  title : 'Title'
}

const DefaultPage = ({title = defaultProps.title, children = defaultProps.children}) => {

  const isAuth = useSelector((state) => state.appReducer.logged);
  const authorizzation = useSelector((state) => state.appReducer.headers.authorizzation);
  const [seeView, setSeeView] = useState(false);
  const navigate = useNavigate();

  let isLoading = useSelector((state) => state.pageState.loading);

  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);

  useEffect(()=>{

    let timerId = null;

    if (!isAuth || authorizzation==='') {
      setSeeView(false);

      timerId = setTimeout(()=>{
        navigate('/');
      }, 3000);
    } else {
      
      setSeeView(true);
      clearTimeout(timerId);
    }

    return ()=>{
      clearTimeout(timerId);
    }
    
  }, [isAuth, authorizzation, navigate]);

  if (!seeView && !isLoading) {

    return (
      <div className='unauthorized-container'>
        <h2>Error 401 - Unauthorized</h2> 
        <span>Devi effettuare il login per visualizzare la pagina</span> 
      </div>
    );
  }
  
  return (
    <>
      <Header title={title} />
      <Navbar/>
      <Body>
        {children}
      </Body>
      <Loading />
    </>
  )

  
}

export default DefaultPage