import React from 'react';
import './CreateNew.scss';

import { IoIosAddCircleOutline } from 'react-icons/io';

const defaultProps = {
    text: 'Add new',
    cssClass: '',
    action: () => { }
};

const CreateNew = ({
    text = defaultProps.text,
    cssClass = defaultProps.cssClass,
    action = defaultProps.action
}) => {
    return (
        <div className={`create-new-container ${cssClass}`} onClick={action}>

            <h3>{text}</h3>
            <IoIosAddCircleOutline className='icon' />
        </div>
    )
}

export default CreateNew;