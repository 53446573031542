import React from 'react';
import './FoodDescription.scss';

// import components

import HeaderSection from '../Sections/HeaderSection/HeaderSection';

const FoodDescription = ({data}) => {

    const descList = () =>{

        let res = [];
        let index = 0;

        for ( let desc in data ) {

            const { displayedName, quantity } = data[desc];

            let tmp = (
                        <li key={index++}>
                            <span className='desc-name'>{displayedName}:</span> 
                            <span className='desc-value'>{quantity}</span> 
                        </li>
            );

            res.push(tmp);
        }

        return res;
    };


  return (
      <div className="food-description">
        <HeaderSection text='Descrizione' color='#E32B2B' cssClass='food-head'/>
          <ul className="desc-container">
              { Object.keys(data).length > 0 && descList() }
          </ul>
      </div>
  )
}

export default FoodDescription