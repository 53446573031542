import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../form.scss';
import useUser from '../../../hooks/user';

import { useDispatch } from 'react-redux';
import { setModalMessage } from '../../../redux/actions/pageState';

const Reset = () => {

  const titleModal = 'Controlla la tua mail';

  const {resetPassword} = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ form, setForm ] = useState({
    "username" : "",
    "email" : ""
  });

  const [ checkField, setCheckField ] = useState( {
    "username" : false,
    "email" : false
  } );

  const [ errMessage, setErrMessage  ] = useState('');

  const handleChange = (e) => {

    const name = e.target.name;
    const value = e.target.value;

    setForm( ( prevState )=>{
      return {
        ...prevState,
        [name] : value
      }
    } );

    if ( value !== "" ) {

      setCheckField( (prevState)=>{
        return {
          ...prevState,
          [name] : false
        }
      } );

    }

  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if ( form.username === "" || form.email === "" ) {

      for ( let field in form ) {

        if ( form[field] === "" ) {

          setCheckField( (prevState)=>{
            return {
              ...prevState,
              [field] : true
            }
          } );

        }
      }
      return;
    }

    let res = await resetPassword(form);

    if (res.data) {
      
      dispatch(setModalMessage(titleModal));
	  navigate('/');
      
    } else {
      setErrMessage(res.message);
    }
  }

  return (
    <form className='form' onSubmit={handleSubmit}>
      <h2>Reset Password</h2>

      <div className="field">
        <label className='label' htmlFor="username">Username</label>
        <input 
          className={ checkField.username ? 'input err-input-border' : 'input' } 
          type="text" 
          name="username" 
          id="username"
          onChange={handleChange}
          value={ form.username }
          autoComplete='off'
        />
        {checkField.username && <span className='err-message'>Compilare il campo</span>}
      </div>

      <div className="field">
        <label className='label' htmlFor="email">Email</label>
        <input 
          className={ checkField.email ? 'input err-input-border' : 'input' } 
          type="email" 
          name="email" 
          id="email" 
          onChange={handleChange}
          value={ form.email }
          autoComplete='off'
        />
      {checkField.email && <span className='err-message'>Compilare il campo</span>}
      </div>

      {errMessage && <span className='err-message message'>{errMessage}</span>}

      <button className='btn' type="submit">Reset password</button>
    </form>
  )
}

export default Reset