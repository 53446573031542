import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import {setLogged, resetState} from '../redux/actions/appSlice';
import {resetPageState} from '../redux/actions/pageState';
import { resetAppData } from '../redux/actions/appData';

import { fetchData, checkLogging, deleteCookie, setCookie } from '../module/connectionFunctions';

export default function useAuth(){
	
	const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const token = useSelector((state) => state.appReducer.headers.authorizzation);
    const id = useSelector((state) => state.appReducer.id);

    //const COOKIE_EXP = 24; //HOURS
    const COOKIE_EXP = 168; //1 WEEK
    
    const isLogged = async() => {
        
        let res = false;

        if (!id && !token) return;

        try {
            res = await checkLogging('auth?id=' + id ,token);
        } catch(err) {
            res = false;
            //console.log(err);
        }
        // returns boolean
		
        if (res){
        
            dispatch(setLogged( {
                //id : res.id,
                logged : res.logged,
            } ));

        } else if(!res) {
            deleteLog();
        }

        return res;

    };

    const logout = async() => {
        if (!id && !token) return;

        let res = false;

        try{

            let r = {
                requestType : 'DELETE',
                url : 'auth?id=' + id,
                auth : token,
            };

            res = await fetchData( r );
        } catch (err) {
            res=false;
            console.log(err);
        }

        if (res && res.data?.logout) {
            deleteLog();
        } 
    };
	
	const login	= async(form) => {

        let r = {
            requestType : 'POST',
            url : 'auth',
            data : form
        };

		let res = await fetchData( r );

		if (res.message && !res.data) {
		  return res.message;
		}

        if (res.data && res.data?.logged) {

            dispatch(setLogged( {
				logged : true
			} ));
        } 
    };  

    const deleteLog = () => {
        dispatch(setLogged( {logged : false} ));

        dispatch( resetPageState() );
        dispatch( resetAppData() );
        dispatch( resetState() );

        //deleteCookie("id");
        //deleteCookie("ssid");
        //deleteCookie("token")
        navigate('/');

    };

    return {isLogged, login, logout, deleteLog}

}