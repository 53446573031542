
import axios from 'axios';

const baseUrl = process.env.REACT_APP_SERVER;

export const fetchData = async ({requestType = 'GET', url, data = {}, auth = false, cache = false, cookie=true}) => {

    let options = {
        method: requestType,
        url,
        baseURL: baseUrl,
    }


    if (requestType != 'GET') {
        options = {
            ...options,
            data
        }
    }

    if (!cache) {
        options.headers = {
            ...options.headers,
            'Cache-Control': 'no-cache',
        }
    } else {
        options.headers = {
            ...options.headers,
            'Cache-Control': 'max-age=3600'
        }
    }
	
	if (cookie) {
        options.withCredentials = true;
	}

    if (auth) {
        options.headers = {
            ...options.headers,
            Authorizzation: "Bearer " + auth,
            //'cors' : 'no-cors'
        }
    }

    try {
        let res = await axios(options)
            .catch((error) => { return error.response });

        let code = res.status;

        if (code >= 200 && code < 300) {
            return {
                data: res.data.data,
                message: ''
            };
        } else if (code > 404 && code < 600) {
            return {
                data: false,
                message: 'Network error!'
            };
        } else {
            return {
                data: false,
                message: res.data.message
            };
        }

    } catch (err) {
        //console.log(err);
    }

};

export const fetchFile = async ({requestType = 'GET', url, formData = {}, auth = false, cache = false, cookie=true}) => {

    let options = {
        method: requestType,
        url,
        baseURL: baseUrl
    }

    if (requestType != 'GET') {
        options.data = formData;
  
    } else {
        options.responseType = "arraybuffer";
    }

    if (!cache) {
        options.headers = {
            ...options.headers,
            'Cache-Control': 'no-cache',
        }
    } else {
        options.headers = {
            ...options.headers,
            'Cache-Control': 'max-age=3600'
        }
    }

    if (cookie) {
        options.withCredentials = true;
	}

    if (auth) {
        options = {
            ...options,
            headers: {
                authorizzation: "Bearer " + auth,
                //'cors' : 'no-cors'
            }
        }
    }

    try {
        let res = await axios(options)
            .catch((error) => { return error.response });

        let code = res.status;

        if (code >= 200 && code < 300) {

            if (requestType != 'GET') {

                return {
                    data: res.data.data,
                    message: ''
                }
            } else {
                return {
                    data: res.data,
                    message: ''
                }
            }
            
        } else if (code > 404 && code < 600) {
            return {
                data: false,
                message: 'Network error!'
            };
        } else {
            return {
                data: false,
                message: res.data.message
            };
        }

    } catch (err) {
        //console.log(err);
    }

};

export const checkLogging = async (url, auth) => {

    let options = {
        method: 'GET',
        headers: {
            'Authorizzation': "Bearer " + auth,
            //'cors' : 'no-cors'
        },
        url,
        baseURL: baseUrl,
        withCredentials : true
    }

    try {
        let res = await axios(options)
            .catch((error) => { return error.response });

        let code = res.status;

        if (code >= 200 && code < 300) {

            return {
                //id: res.data.id,
                logged: true
            };
        } else if (code > 404 && code < 600) {
            return {
                data: false,
                message: 'Network error!'
            };
        } else {
            return false;
        }

    } catch (err) {
        return false;
    }
};

export function setCookie(name, value, expiresHours) {

    let expiresDate = new Date();
    let expiresUTCHours = expiresHours + (-1 * expiresDate.getTimezoneOffset() / 60);

    expiresDate.setTime(expiresDate.getTime() + (expiresUTCHours * 60 * 60 * 1000));

    let exp = " Expires=" + expiresDate.toUTCString() + ";";
    let domain = " Domain=" + process.env.REACT_APP_COOKIE_DOMAIN + ";";
    let path = " Path=" + process.env.REACT_APP_COOKIE_PATH + ";";
    let secure = " " + process.env.REACT_APP_COOKIE_SECURE + ";";
    let samesite = " SameSite=" + process.env.REACT_APP_COOKIE_SAMESITE + ";";
    let httpOnly = " " + process.env.REACT_APP_COOKIE_HTTPONLY + ";";

    let cookie = "";

    cookie = name + "=" + value + ";" + exp;

    /* if (process.env.NODE_ENV == "development") {
        cookie = name + "=" + value + ";" + exp;
    } else if (process.env.NODE_ENV == "production") {
        cookie = name + "=" + value + ";" + path + exp + domain + secure + httpOnly + samesite;
    } */

    document.cookie = cookie;
}

export function getCookie(name) {

    let cookieName = name + "=";
    let cookieArray = document.cookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }

    return null;
}

export function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}