import React, {useEffect, useState, useRef} from 'react';
import './Chart.scss';

// import components

import { PieChart } from '@mui/x-charts/PieChart';

const Chart = ({data}) => {

    const chartElem = useRef(null);

    const [chartWidth, setChartWidth] = useState(0);

    useEffect(()=>{

        setChartWidth(chartElem.current.clientWidth);

        const resizeWindow = () =>{
            setChartWidth(chartElem.current.clientWidth);
        };

        window.addEventListener('resize', resizeWindow);
        
        return ()=>{
            
            window.removeEventListener('resize', resizeWindow);
        }
    }, []);

        const carbo = data?.carbohydratesTot?.quantity ? data.carbohydratesTot.quantity : 0;
        const prot = data?.proteinsTot?.quantity ? data.proteinsTot.quantity : 0;
        const fat = data?.fatsTot?.quantity ? data.fatsTot.quantity : 0;
        const kcal = data?.kcal?.quantity ? data.kcal.quantity : 0;
     
     let totMacro = + carbo + prot + fat;

     const macro = [
        {
            name : 'Carboidrati',
            perc : +(+carbo * 100 / totMacro).toFixed(1),
            quantity : carbo,
            color: '#80CAFF'
        },
        {
            name : 'Proteine',
            perc : +(+prot * 100 / totMacro).toFixed(1),
            quantity : prot,
            color: '#85E0A3'
        },
        {
            name : 'Grassi',
            perc : +(+fat * 100 / totMacro).toFixed(1),
            quantity : fat,
            color: '#FFD966'
        },
     ];

    const series = [
        {
            data: macro.map((elem, index)=>{
                return {
                    id : index,
                    value : elem.perc,
                    label : elem.name,
                    color : elem.color
                };
            }),
            arcLabel: (item) => `${item.value}%`,
            innerRadius: chartWidth * 0.3,
            outerRadius: chartWidth * 0.5,

        },
    ];

    const style = {
        height : chartWidth + 10,
        width : chartWidth + 10,
        margin : { top: 0, bottom: 0, left: 0, right:0 },
    }

    const sx = {
        //fill:'#FFFFFF',
        fontWeight: 'bold',
    };

    const slotProps = {
        legend: {
            hidden: true,
            direction: 'column',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0,
        },
    }
      
  return (
    <div className="chart-container">
        <div className="chart" ref={chartElem}>
            <PieChart series={series} slotProps={slotProps} {...style} sx={sx}/>
        </div>

        <div className="macro-container">
            <div className="macro">
                <div className="circle" style={{backgroundColor: '#444444'}}></div>
                <span className="macro-text"> Totale kcal: </span>
                <span className="macro-value">{kcal}</span>
            </div>
            {
                  macro.map((elem, index) => {
                      return (
                        <div className="macro" key={index}>
                            <div className="circle" style={{ backgroundColor: elem.color }}></div>
                            <span className="macro-text">{elem.name} : </span>
                            <span className="macro-value">{elem.quantity} g</span>
                        </div>
                      )
                  })
            }
        </div>

    </div>
  )
}

export default Chart