import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import './BeginPage.scss';

const Login = ({children}) => {

  let navRef = useRef(null);
  const formRef = useRef(null);

  const navigate = useNavigate();
  
  useEffect( ()=>{

    const hideNavbar = (e)=>{

      if (!navRef || !navRef.current) return;
  
      let navHeight = getComputedStyle(navRef.current).getPropertyValue("height");
      navHeight = Number(navHeight.split("px")[0]);
  
      let transition = getComputedStyle(navRef.current).getPropertyValue("transition-duration");
      transition = Number(transition.split("s")[0]);
  
      let maxScroll = formRef.current.offsetTop - navHeight;
  
      let scroll = window.scrollY;
  
      if ( scroll > maxScroll ) {
        navRef.current.classList.add("navbar-hidden");
  
        setTimeout(()=>{
          if (!navRef) return;
          navRef.current.classList.add("display-none");
        }, transition);
  
      } else if ( scroll <= maxScroll ) {
        navRef.current.classList.remove("navbar-hidden");
  
        setTimeout(()=>{
          if (!navRef) return;
          navRef.current.classList.remove("display-none");
        }, transition);
      }
  
    };

    window.addEventListener( 'scroll', hideNavbar );
    window.scrollTo(0,0);

    return ()=>{
      window.removeEventListener( 'scroll', hideNavbar );
    };
    
  }, [] );

  return (
    
    <div className='page'>
      <nav className="navbar-begin-page" ref={navRef}>

        <div className="title">
          <span className='welcome-to'>Welcome to</span>
          <span className='logo' onClick={()=>{ navigate('/') }}>GPlans</span>
        </div>
      </nav>

      <section className="hero">
        <div className="form-container" ref={formRef}>
          {children}
        </div>
      </section>
      
    </div>
  )
}

export default Login