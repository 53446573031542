import React, { useEffect, useState } from 'react';
import '../form.scss';
import './SignUp.scss';

import { useDispatch, useSelector } from 'react-redux';
import { registration, setPage } from '../../../redux/actions/appSlice';

const SignUp = () => {

  const dispatch = useDispatch();
  const step = useSelector((state) => state.appReducer.registration.step1);

  

  const [ form, setForm ] = useState({
    "username" : "",
    "email" : "",
    "password" : "",
    "rePassword" : "",
    "terms" : false 
  });

  useEffect(() => {
    if (step) {
      setForm(
        {
          "username" : step.username,
          "email" : step.email,
          "password" : step.password,
          "rePassword" : step.password,
          "terms" : false
        }
      );
    }
  }, [step]);

  const [ checkField, setCheckField ] = useState( {
    "username" : false,
    "email" : false,
    "password" : false,
    "rePassword" : false,
    "terms" : false
  } );

  const [ message, setMessage ] = useState( "" );


  const checkAllFields = ( form )=> {

    let result = true;

    for ( let field in form ) {

      if ( form[field] === "" ){

        setCheckField( (prevState)=>{
          return {
            ...prevState,
            [field] : true
          }
        } );

        result =  false;
      }

    }

    return result;
  };

  const handleChange = (e) => {

    const name = e.target.name;
    const value = e.target.type == "checkbox" ? e.target.checked : e.target.value;

    setMessage( "" );

    setForm( ( prevState )=>{
      return {
        ...prevState,
        [name] : value
      }
    } );

    if ( value != "" ) {

      setCheckField( (prevState)=>{
        return {
          ...prevState,
          [name] : false
        }
      } );

    }

  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    setMessage( "" );

    if ( checkAllFields(form) ) {

      if ( form.terms == false ) {

        setMessage( "Accettare termini e condizioni" );

        return;
      }

      if ( form.password !== form.rePassword ) {

        setMessage( "Le password non combaciano!" );

        return;
      }

      dispatch( registration( {
        step: "step1",
        data : {
          "username" : form.username,
          "password" : form.password,
          "email" : form.email,
        }
      } ) );

      dispatch(setPage({page : 2}));

      return
    } else {

      setMessage( "Compilare correttamente tutti i campi!" );
      return;
    }

  }


  return (
    <form className='form' onSubmit={handleSubmit}>
      <h2>SignUp</h2>

      <div className="field">
        <label className='label' htmlFor="username">Username</label>
        <input 
          className={ checkField.username ? 'input err-input-border' : 'input' } 
          type="text" 
          name="username" 
          id="username" 
          onChange={handleChange}
          value={ form.username }
          autoComplete='off'
        />
      </div>

      <div className="field">
        <label className='label' htmlFor="email">E-mail</label>
        <input 
          className={ checkField.email ? 'input err-input-border' : 'input' } 
          type="email" 
          name="email" 
          id="email" 
          onChange={handleChange}
          value={ form.email }
          autoComplete='off'
        />
      </div>

      <div className="field">
        <label className='label' htmlFor="password">Password</label>
        <input 
          className={ checkField.password ? 'input err-input-border' : 'input' } 
          type="password" 
          name="password" 
          id="password" 
          onChange={handleChange}
          value={ form.password }
        />
      </div>

      <div className="field">
        <label className='label' htmlFor="rePassword">Reinserisci password</label>
        <input 
          className={ checkField.rePassword ? 'input err-input-border' : 'input' } 
          type="password" 
          name="rePassword" 
          id="rePassword" 
          onChange={handleChange}
          value={ form.rePassword }
        />
      </div>

      <div className="terms-container">
        <input 
          className='check-box' 
          type="checkbox" 
          name="terms" 
          id="terms" 
          onChange={handleChange}
          checked={ form.terms }
        />
        <label className='label' htmlFor="terms">
			Accetto di aver letto la <a target='_blank' rel="noreferrer" href="https://legal.gplans.it/privacy_policy" >privacy policy</a> e <a target='_blank' rel="noreferrer" href="https://legal.gplans.it/terms_conditions/">termini e condizioni</a>
		</label>
      </div>

      {message && <span className='err-message message'>{message}</span>}

      <button className='btn' type="submit">Procedi</button>
    </form>
  )
}

export default SignUp