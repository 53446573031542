import React, { useEffect, useState } from 'react';
import './SearchBar.scss';

const defaultProps = {
  data : [],
  action : ()=>{},
  getData : null
};

//const VIEW_LIMIT = window.innerWidth > 500 ? 10 : 6;
//const VIEW_LIMIT = 6;

const SearchBar = ({
  data = defaultProps.data,
  action = defaultProps.action,
  getData = defaultProps.getData

}) => {

  const [search, setSearch] = useState('');

  // manage search list
  const [isSearching, setIsSearching] = useState(false);

  // containts principal data
  const [items, setItems] = useState([]);

  // manage list of items
  const [inputFields, setInputFields] = useState([]);

  // Clear listener

  useEffect(()=>{
    return ()=>{
      document.body.removeEventListener('click', manageModal);
    }
  }, [])

  // When component is mounted


  useEffect(()=>{
    if ( data.length !== 0 ) {
      setItems(data);
    }
  }, [data]);

  const handleChange = (e) => {

    if (!e.target) return;

    let value = e.target.value.toLowerCase();

    setSearch(value);

    if (value.length < 3) {
      setInputFields([]);
    }

    // if exists a fetch function for getting data
    if (getData) {
      if (value.length == 3) {
        getData(value).then((data)=>{
          let res = data ? data : [];
          
          setItems(res);
          setInputFields(res);
        });
      }
    }

    if (value.length > 3) {

      let tmp = items.filter(item => item.name.toLowerCase().includes(value));
      setInputFields(tmp);
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //if (inputFields.length !== 1 ) return;

    action(inputFields[0]);

    closeSearch();
    
  };

  /*------------management-of-input------------*/

  const formFocus = () => {
    if (!isSearching) {
      document.body.addEventListener('click', manageModal);
      setIsSearching(true);
    }
  }

  const manageModal = (e) => {

    if (!e.target) return;

    if (!e.target.closest('.search-bar')) {

      closeSearch();
    }

  };

  const closeSearch = () => {

    setItems([]);
    setInputFields([]);
    setSearch('');

    setIsSearching(false);
    document.body.removeEventListener('click', manageModal);

  };

  /*-----------Graphic-fields-render-----------*/

  const fieldRendering = () => {

    let tmp = inputFields.map((item, index)=>{
      const { name } = item;

      return (
        <li 
          key={index}
          onClick={()=>{
            action(item);
            closeSearch();
          }}
          tabIndex={index+1}
        >{name}</li>
      );
    });

    //return tmp.slice(0, VIEW_LIMIT);
    return tmp;
  }


  return (
    <form 
      className={`search-bar ${inputFields.length > 0 ? 'full-search-bar' : ''}`}
      onFocusCapture={formFocus}
      onSubmit={handleSubmit}>

        <input 
          type="text" 
          name="search" 
          id="search"
          autoComplete='off'
          value={search}
          onInput={handleChange} 
        />
        { 
          inputFields.length > 0 && 
            <div className="search-items">
              <div className="line"></div>
              <ul>
                {
                  fieldRendering()
                }
              </ul>
            </div>
        }
    </form>
  )
}

export default SearchBar