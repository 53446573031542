import React, {useRef} from 'react';
import './ModalElem.scss';

import { IoClose } from "react-icons/io5";

const defaultProps = {
  action : ()=>{}
}

const ModalElem = ({children, action = defaultProps.action}) => {

  const modal = useRef(null);

  const manageModal = (e)=>{
  
    if (e.target.classList.contains('modal-container')) {
      action();
    }
  };

  return (
    <aside className='modal-elem' ref={modal} onClick={manageModal}>
        <div className="modal-container">
            <div 
              className="close-btn"
              onClick={()=>{ action() }}
            >
              <IoClose className='icon'/>
            </div>
            
            {children}
        </div>
    </aside>
  )
}

export default ModalElem