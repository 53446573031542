import React from 'react'
import './Header.scss';

import { useSelector } from 'react-redux';

const Header = ({ title, children }) => {

    const isOpen = useSelector((state) => state.pageState.isOpenNavbar);

    return (
        <header className={`header ${ isOpen ? 'nav-margin-open' : 'nav-margin-close'}`} >
            <h1>{title}</h1>
            {children}
        </header>
    )
}

export default Header