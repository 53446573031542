import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './AddFoodPage.scss';

/*----------------------------------HOOKS-------------------------------*/

import useFoodActions from '../../hooks/foodActions';

/*--------------------------------CLASSES---------------------------------*/

import { detailsBasic, detailsFields } from '../../class/classes';

/*-------------------------------COMPONENTS-------------------------------*/

import DefaultPage from '../default-page/DefaultPage';
import HeaderSection from '../../components/Sections/HeaderSection/HeaderSection';
import SwitchBtn from '../../components/SwitchBtn/SwitchBtn';

const AddFoodPage = () => {

    const navigate = useNavigate();

    const { addNewFood } = useFoodActions();

    const [ form, setForm ] = useState({});
    const [ details, setDetails ] = useState(new Map());

    const [ isBasic, setisBasic ] = useState(true);

    const [ errMessage, setErrMessage ] = useState('');

    // SET FORM FIELDS DINAMICALLY

    useEffect(()=>{

        if (isBasic) {
            setDetails(detailsBasic);
        } else {
            setDetails(detailsFields);
        }
    }, [isBasic]);

    useEffect(() => {
        //CREATE FORM OBJECT

        let tmp = {};

        details.forEach((value, key) => {

            if (key !== 'id'){
                tmp = {
                    ...tmp,
                    [key]: ''
                };
            }

        });

        setForm(tmp);

    }, [details]);

    const changeBasic = ()=> {
        setisBasic((prevState)=>{
            return !prevState;  
        });
    };

    const handleChange = (e) => {
        let { name, value } = e.target;

        if ( value < 0 ) {
            value = 0;
        }

        setForm((prevState)=>{
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // check the necessary fields

        for (let input in form) {

            if (detailsBasic.has(input)){
                if (form[input] === '') {
                    alert('Compilare tutti i campi necessari!');
                    return;
                }
            }
        }

        let res = await addNewFood(form);  
        
        if (res) {
            navigate('/setting');
        }
    };

    const writeForm = () => {
        let res = [];

        if (Object.keys(form).length === 0 ) return;
        
        details.forEach((value, key) => {

            let tmp = null;
            let necessary = false;
            let inputType = key === 'foodName' ? 'text' : 'number'

            if (detailsBasic.has(key)) {
                necessary = true;
            }

            if (key === 'id') return;
            
            tmp = (
                <div className="row" key={key} >
                    <div className="label-container">
                        {
                            necessary && <span className='cl-danger'>*</span>
                        }
                        <label htmlFor={key}>{value}</label>
                    </div>
                    <input 
                        type={inputType} 
                        step='0.01' 
                        name={key} 
                        id={key} 
                        className='input' 
                        autoComplete='off' 
                        onChange={handleChange} 
                        value={form[key]}
                    />
                </div>
            );

            res.push(tmp);
        });

        return res;
    };

  return (
    <DefaultPage title='Aggiungi alimento'>
        <div className='page-container add-food-page'>
            <SwitchBtn 
                text={ isBasic ? 'Base' : 'Completo' }
                action={changeBasic}
                cssClass='basic'
            />
            <div className="page-data">
                <HeaderSection text='Nuovo alimento:' />
                <form onSubmit={(e)=>{handleSubmit(e)}}>

                    { details && writeForm() }

                    { errMessage && <span className="err-message">{errMessage}</span> }
                    <button 
                        className="btn btn-success"
                        type='submit'
                    >Aggiungi</button>
                </form>
            </div>
        </div>
    </DefaultPage>
  )
}

export default AddFoodPage