import React from 'react'
import BeginPage from '../../components/begin-page/BeginPage';
import Login from '../../components/Form/login/Login';


const LoginPage = () => {

  //const baseUrl = process.env.REACT_APP_SERVER;

  return (
    <BeginPage>
      <Login/>
    </BeginPage>
  )
}

export default LoginPage