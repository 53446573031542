import React, {useState} from 'react';
import './ModalChange.scss';

import ModalElem from '../../ModalElem/ModalElem';
import HeaderSection from '../HeaderSection/HeaderSection';

const defaultProps = {
	closeModal : ()=>{},
	action : ()=>{},
	placeHolder : '',
  text : 'Nuovo',
  inputType : 'text',
  btnClass : ''
};

const inlineStyle = {
	minHeight: '20em',
	height:'60%',
	display: 'flex',
	flexDirection : 'column',
	justifyContent : 'center',
	alignItems : 'center',
  position: 'relative'
};

const ModalChange = ({
  closeModal = defaultProps.closeModal,
  action = defaultProps.action,
  placeHolder = defaultProps.placeHolder,
  text = defaultProps.text,
  inputType = defaultProps.inputType,
  btnClass = defaultProps.btnClass
}) => {

    const [ input, setInput ] = useState(placeHolder);
	

    const handleChange = (e)=> {
        let value = e.target.value;

        setInput(value);
    };
	
  const handleSubmit = (e) => {

    e.preventDefault();
    let value = e.target.planName.value;
    
    action(value);
    closeModal();
  };

  return (
    <ModalElem action={closeModal}>
      <div className='box' style={inlineStyle}>
        <form className='modal-change-value' onSubmit={handleSubmit}>
          <HeaderSection text={text} cssClass='head'/>
          {/* <label htmlFor='planName'>Nuovo nome:</label> */}
          <input
            className='input'
            type={inputType}
            id='planName'
            name='planName'
            value={input}
            onChange={handleChange}
            autoComplete='off'
            placeholder='inserisci qui'
          />
          <button type='submit' className={`btn ${btnClass}`}>Ok</button>
        </form>
      </div>
    </ModalElem>
  )
}

export default ModalChange
