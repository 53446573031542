import React from 'react'
import BeginPage from '../../components/begin-page/BeginPage';
import Reset from '../../components/Form/reset/Reset';

const ResetPage = () => {

  return (
    <BeginPage>
      <Reset />  
    </BeginPage>
  )
}

export default ResetPage