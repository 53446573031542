export function IdGenerator(length=8) {

    let maxLength = 8;
    let timestamp = +new Date();

    if (length > 8 ) { 
        length = maxLength;
    } else if (length <= 0) {
        return 0;
    }

    let ts = timestamp.toString();
    let parts = ts.split("").reverse();
    let id = "";

    for (let i = 0; i < length; ++i) {
        let index = getRandomInt(0, parts.length - 1);
        id += parts[index];
    }

    let letters = makeRandomString(2);

    return letters + id;
};

export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function makeRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

// wants string YYYY-MM-DD

export function ageFromBirth(birth) {

    let dateObj = new Date(birth);
    let today = Date.now();
    
    let diff = today - dateObj;
    diff = new Date(diff);
    
    return Math.abs(diff.getUTCFullYear() - 1970);
}

// return url of img

export function getFileBase64(arrayBuffer) {
    const base64 = btoa(
      new Uint8Array(arrayBuffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    return `data:image/jpeg;base64,${base64}`;
  }