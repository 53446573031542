import React from 'react';
import './Body.scss';

import { useSelector } from 'react-redux';

const Body = ({children}) => {

  const isOpen = useSelector((state) => state.pageState.isOpenNavbar);

  return (
    <section className={`body ${ isOpen ? 'nav-margin-open' : 'nav-margin-close'}`}>
        {children}
    </section>
  )
}

export default Body