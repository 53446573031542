import React from 'react';

import { Link } from 'react-router-dom';

/*-------------------------------COMPONENTS-------------------------------*/

import DefaultPage from '../default-page/DefaultPage';


const ErrorPage = () => {

    const style = {
        padding: '2em',
    }

  
  return (
    
    <DefaultPage title=''>
        <div className="page-data flex-center" style={style}>
            <h1>La pagina non esiste, torna alla <Link className='link' to='/home'>home</Link></h1>
        </div>
    </DefaultPage>
  );

  
}

export default ErrorPage