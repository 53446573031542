import { IdGenerator } from "../module/library";

    export const detailsBasic = new Map([
        ["id", "id"],
        ["foodName", "Nome"],
        ["kcal", "Energia kcal"],
        ["carbohydratesTot", "Carboidrati"],
        ["sugars", "Zuccheri semplici"],
        ["proteinsTot", "Proteine totali"],
        ["fatsTot", "Grassi Totali"],
        ["saturatedFats", "Grassi saturi"]
    ]);
    export const detailsFields = new Map([
        ["id", "id"],
        ["foodName", "Nome"],
        ["ediblePart", "Parte edibile"],
        ["kcal", "Energia kcal"],
        ["kj", "Energia kj"],
        ["carbohydratesTot", "Carboidrati"],
        ["sugars", "Zuccheri semplici"],
        ["proteinsTot", "Proteine totali"],
        ["proteinsAnimal", "Proteine animali"],
        ["proteinsVeg", "Proteine vegetali"],
        ["fatsTot", "Grassi Totali"],
        ["saturatedFats", "Grassi saturi"],
        ["monounsaturatedFats", "Grassi monoinsaturi"],
        ["polyunsaturatedFats", "Grassi polinsaturi"],
        ["otherPolins", "Altri polins."],
        ["water", "Acqua"],
        ["starch", "Amido"],
        ["oleicAcid", "Acido oleico"],
        ["linoleicAcid", "Acido linoleico"],
        ["linolenicAcid", "Acido linolenico"],
        ["cholesterol", "Colesterolo"],
        ["fibers", "Fibre"],
        ["alcool", "Alcool"],
        ["iron", "Ferro"],
        ["calcium", "Calcio"],
        ["sodium", "Sodio"],
        ["potassium", "Potassio"],
        ["phosphorus", "Fosforo"],
        ["zinc", "Zinco"],
        ["vitB1", "Vitamina B1"],
        ["vitB2", "Vitamina B2"],
        ["vitB3", "Vitamina B3"],
        ["vitC", "Vitamina C"],
        ["vitB6", "Vitamina B6"],
        ["folicAcid", "Acido folico"],
        ["retinolEq", "Renitolo equiv."],
        ["betaCaroteneEq", "Beta carotene eq."],
        ["vitE", "Vitamina E"],
        ["vitD", "Vitamina D"]
    ]);

export class Plan {
    constructor({code, name, meals = []}) {
        this.code = code ? code : IdGenerator(4);
        this.name = name;
        this.meals = meals;
    }

    createObj() {
        let res = {};

        for ( let item in this ) {
            res[item] = this[item];
        }

        return res;
    }
}

export class Meal {
    constructor({code, name, foods=[], order=0}) {

        this.code = code ? code : IdGenerator(5);
        this.name = name;
        this.foods = foods;
        this.order = order
    }

    createObj() {
        let res = {};

        for ( let item in this ) {
            res[item] = this[item];
        }

        return res;
    }
}

export class Food {
    constructor({ id=false, quantity=0, details={} }) {

        this.id = id ? id : IdGenerator(4);
        this.quantity = quantity;
        this.name = details?.foodName ? details.foodName : '';
        this.details = this.createDetails(details);
    }

    createDetails(details={}) {

        if ( Object.keys(details).length === 0 ) return details;

        let tmp = {};

        for (let prop in details) {

            if (detailsFields.has(prop)) {
                
                let name = detailsFields.get(prop);
    
                tmp[prop] = {
                    displayedName: name,
                    quantity: details[prop]
                };
            } else {
                tmp[prop] = {
                    displayedName: prop,
                    quantity: details[prop]
                };
            }
        }

        return tmp;
    } 

    createObj() {
        let res = {};

        for ( let item in this ) {
            res[item] = this[item];
        }

        return res;
    }
}
// rewrite data in request in Plan, Meal, Food obj
export class Rewrite {

    static createFood(foods) {
        let newFoods = [];

        // Rewrite FOODS array

        for (let food in foods) {
            
            let f = null;

            if ( foods[food].hasOwnProperty('details') ) {
                f = new Food(foods[food]);
            } else {
                f = new Food({
                    id: foods[food].id,
                    quantity : foods[food].ediblePart,
                    details : foods[food]
                });
            }
            newFoods.push(f.createObj());
        }

        return newFoods;
    }

    static createMeal(meals) {
        let newMeals = [];

        for (let meal in meals) {

            const { meal_code: code, meal_name: name, meal_order: order, foods } = meals[meal];

            // Rewrite FOODS array

            let newFoods = this.createFood(foods);

            // Rewrite MEALS array

            let m = new Meal({ code, name, foods: newFoods, order });
            newMeals.push(m.createObj());
        }

        return newMeals;
    }

    static createPlan(plan) {

        let { plan_code: code, plan_name:name, meals } = plan;

        // Rewrite MEALS array and FOODS array

        let newMeals = this.createMeal(meals);

        let p = new Plan({ code, name, meals:newMeals });
		let newPlan = p.createObj();

        return newPlan;
    }
}