import React from 'react';
import './ModalMessage.scss';

const defaultProps = {
  title: 'Example title',
  action : ()=>{}
}

const ModalMessage = ({title = defaultProps.title, action = defaultProps.action}) => {
  return (
    <aside className='modal'>
        <div className="container">
            <h2>{title}</h2>
            <button className='btn' onClick={()=>{ action() }}>Ok</button>
        </div>
    </aside>
  )
}

export default ModalMessage