import React, { useState } from 'react';
import './SearchFood.scss';

/*------------------------------CUSTOM-HOOKS------------------------------*/

import usePlanRequest from '../../hooks/planRequest';

/*-------------------------------COMPONENTS-------------------------------*/

import DefaultPage from '../default-page/DefaultPage';

import SearchBar from '../../components/SearchBar/SearchBar';

import PlanDescription from '../../components/Sections/PlanDescription/PlanDescription';

const SearchFood = () => {

  const { getFoods } = usePlanRequest();

  const [ food, setFood ] = useState({});
  const [ title, setTitle ] = useState('');

  const setSearch = (item) => {

    setTitle(item.name);

    let tmp = {};

    for (let desc in item.details) {
      //console.log(desc)
      if (desc != 'id' && desc != 'foodName') {
        tmp = {
          ...tmp,
          [desc] : item.details[desc]
        };
      }      
    }

    setFood(tmp);
  };
  
  return (
    
    <DefaultPage title='Cerca alimenti'>
      <section className='section-search-food'>
        <div className="search-bar-container">
          <SearchBar getData={getFoods} action={setSearch} />
        </div>
        <h1 className='title'>{title}</h1>
        <PlanDescription data={food} />
      </section>
    </DefaultPage>
  );

  
}

export default SearchFood