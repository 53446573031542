import { useDispatch, useSelector } from 'react-redux'

/*----------------------------------STATE-------------------------------*/

import { setUserData, setValidation, clearRegistation, resetState } from '../redux/actions/appSlice';
import { setModalMessage, resetPageState } from '../redux/actions/pageState';
import { resetAppData } from '../redux/actions/appData';

/*----------------------------------HOOKS-------------------------------*/

import useAuth from './auth';

/*---------------------------------MODULE-------------------------------*/

import { fetchData, fetchFile, deleteCookie } from '../module/connectionFunctions';

/*------------------------------IMPORT-LIBRARY--------------------------*/

import { getFileBase64 } from '../module/library';

export default function useUser(){

    const baseUrl = process.env.REACT_APP_SERVER;
	
	const dispatch = useDispatch();
    const token = useSelector((state) => state.appReducer.headers.authorizzation);
    const userId = useSelector((state) => state.appReducer.id);

    const {logout} = useAuth();

    
    /*----------------------------GET-FUNCTIONS-------------------------------*/
    
    const getUserData = async(id, cache=true) => {
        
        let res = false;

        let request = 'user/' + id;

        let r = {
            url : request,
            auth : token,
        };

        if (!cache) {
			r.cache = cache
		}
        
        try {
            res = await fetchData(r);
        } catch(err) {
            res = false;
        }
        // returns boolean
        if (res.data){       

            let img = await getUserImg(id);

            if (img) {
                img = getFileBase64(img);
            } else {
                img = '';
            }

            dispatch(setUserData( {data : {
                ...res.data,
                profile_img : img
            }} ));

        } else {
			console.log(res.message);
		}

    };

    const getUserImg = async(id, cache=true) => {
        
        let res = false;

        let request ='user/profileImg?userId=' + id;

        let r = {
            url : request,
            auth : token,
        };

        if (!cache) {
			r.cache = cache
		}
        
        try {
            res = await fetchFile(r);
        } catch(err) {
            res = false;
        }
        // returns boolean
        if (res.data){            
            return res.data;

        }

    };

    /*----------------------------POST-FUNCTIONS------------------------------*/

    const signup = async ( data )=> {

        let res = false;

        let request = 'user';

        let r = {
            requestType : 'POST',
            url : request,
            data,
        };
        
        try {
            res = await fetchData(r);
        } catch(err) {
            res = false;
            console.log(err);
        }
 
        if (res.data){            

            dispatch( clearRegistation({}) );
            dispatch( setModalMessage('Utente creato con successo') );

        }

        return res;
        
      };

      const checkPassword = async(password) => {
        
        let res = false;

        let request = 'auth/password?id=' + userId;

        let body = {
            password
        }

        let r = {
            requestType : 'POST',
            url : request,
            data : body,
            auth : token,
        };
        
        try {
            res = await fetchData(r);
        } catch(err) {
            res = false;
            console.log(err);
        }

        if (res.data) {
            return true
        }
        // returns boolean
        return false;

    };

    /*-----------------------------PUT-FUNCTIONS------------------------------*/

    const changePassword = async(form) => {
        
        let res = false;

        let request = 'user/password?id=' + userId;

        let r = {
            requestType : 'PUT',
            url : request,
            data : form,
            auth : token,
        };
        
        try {
            res = await fetchData(r);
        } catch(err) {
            res = false;
            console.log(err);
        }

        if (res.data) {
            await logout();
        }
        // returns boolean
        return res;

    };

    const resetPassword = async(form) => {
        
        let res = false;

        let request = 'user/forgotPassword';

        let r = {
            requestType : 'PUT',
            url : request,
            data : form,
            auth : token,
        };
        
        try {
            res = await fetchData(r);
            console.log(res)
        } catch(err) {
            //res = false;
            res = {
                data : [],
                message: 'Network error'
            };

            console.log(err);
        }
        // returns boolean
        return res;

    };

    const updateUserInfo = async(data) => {
        
        let res = false;

        let request = 'user/userInfo?id=' + userId;

        let r = {
            requestType : 'PUT',
            url : request,
            data,
            auth : token,
        };
        
        try {
            res = await fetchData(r);
        } catch(err) {
            res = false;
            console.log(err);
        }

        if (res.data) {
            await getUserData(userId, false);
            return true;
        }
        // returns boolean
        return res;

    };

    const uploadImg = async(formData) => {
        
        let res = false;

        let r = {
            requestType : 'POST', 
            url : 'user/profileImg?userId=' + userId, 
            formData,
            auth : token
        }
        
        try {
            res = await fetchFile(r);
        } catch(err) {
            res = false;
            console.log(err);
        }

        // returns boolean

        if (res.data) {
            await getUserData(userId, false);            
            return true;
        }
        return res;

    };

    const deleteUser = async (password)=> {
        let res = false;

        let body = {
            password
        }

        let request = 'user?id=' + userId;

        let r = {
            requestType : 'DELETE',
            url : request,
            data : body,
            auth : token,
        };
        
        try {
            res = await fetchData(r);
        } catch(err) {
            res = false;
            console.log(err);
        }

        // returns boolean

        if (res.data) {
            dispatch( resetState() );
            dispatch( resetAppData() );
            dispatch( resetPageState() );

            deleteCookie("token");
            deleteCookie("id");

            return true;
        }
        return false;
    }

    /*---------------------------DELETE-FUNCTIONS-----------------------------*/

    return {getUserData, getUserImg, signup, changePassword, checkPassword, resetPassword, updateUserInfo, uploadImg, deleteUser}
}