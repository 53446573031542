import React from 'react';
import './DivideLine.scss';

const defaultProps = {
  width: '2px',
  height: '1px'
};

const DivideLine = ({width = defaultProps.width, height = defaultProps.height}) => {

  let style = {
    width : width,
    height : height
  };

  return (
    <div className="divide-line" style={style}></div>
  )
}

export default DivideLine