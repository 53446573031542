/*----------------------Import-react-router-dom----------------------*/
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

/*..-------------------------Import-PAGES----------------------------*/

//import './variables.scss';
import './App.scss';
import LoginPage from './pages/login-page/LoginPage';
import SignUpPage from './pages/signup-page/SignUpPage';
import ResetPage from './pages/reset-page/ResetPage';

import ErrorPage from './pages/error-page/ErrorPage';

import HomePage from './pages/home-page/HomePage';
import PlanPage from './pages/plan-page/PlanPage';
import SingleMealPage from './pages/single-meal-page/SingleMealPage';
import SearchFood from './pages/search-food/SearchFood';
import ProfilePage from './pages/profile-page/ProfilePage';
import SettingPage from './pages/setting-page/SettingPage';
import AddFoodPage from './pages/add-food-page/AddFoodPage';

/*-------------------------------COMPONENTS-------------------------------*/

import ModalMessage from './components/ModalMessage/ModalMessage';

/*--------------------------Import-Functions-------------------------*/

import { useDispatch, useSelector } from 'react-redux'
import { setAuth } from './redux/actions/appSlice';
import { setModalMessage } from './redux/actions/pageState';

import useAuth from './hooks/auth';
import useUser from './hooks/user';
import { getCookie } from './module/connectionFunctions';

function App() {
  
  const { isLogged, deleteLog } = useAuth();
  const { getUserData } = useUser();
	
	const dispatch = useDispatch();

  const token = process.env.REACT_APP_HEADER;
  const isAuth = useSelector((state) => state.appReducer.logged);
  const userId = useSelector((state) => state.appReducer.id);
  const modalMessage = useSelector((state) => state.pageState.modalMessage);
	
	useEffect(()=>{
    const loadCookie = ()=>{
      
      let id = getCookie("id");
      if( token && id ) {
        dispatch( setAuth({token, id}) );
      }
    };
    
		loadCookie();

	}, [dispatch, document.cookie]);

  useEffect(()=>{
    if (userId !== '') {
      isLogged();
    } 

  }, [userId, isAuth]);

  useEffect(()=>{
    if (isAuth && userId) {
      
      getUserData(userId);
    } 
  }, [getUserData, isAuth, userId]);
	
  return (
    <>

      {modalMessage && <ModalMessage title={modalMessage} action={() => {
        dispatch(setModalMessage(''));
      }} />}

      <Routes>

        <Route exact path='/' element={
          <LoginPage/>
        }/>

        <Route exact path='/signup' element={
          <SignUpPage/>
        }/>

        <Route exact path='/resetPassword' element={
          <ResetPage/>
        }/>

        <Route exact path='/home' element={
          <HomePage/>
        }/>

        <Route exact path='/plan/:id' element={
          <PlanPage/>
        }/>
        <Route exact path='/meal/:id' element={
          <SingleMealPage/>
        }/>
        <Route exact path='/meal/newMeal' element={
          <SingleMealPage/>
        }/>

        <Route exact path='/searchFood' element={
          <SearchFood/>
        }/>

        <Route exact path='/addFood' element={
          <AddFoodPage/>
        }/>

        <Route exact path='/profile' element={
          <ProfilePage/>
        }/>

        <Route exact path='/setting' element={
          <SettingPage/>
        }/>

        <Route exact path='*' element={
          <ErrorPage/>
        }/>

      </Routes>
    </>
  );
}

export default App;
