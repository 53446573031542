import React, {useState, useEffect} from 'react';

import { useNavigate } from 'react-router-dom';

import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { RiUser3Fill, RiLogoutBoxRFill } from "react-icons/ri";
import { IoIosHome, IoIosSettings } from "react-icons/io";

import './Navbar.scss';
import './NavbarMobile.scss';

import LogoutBtn from '../LogoutBtn/LogoutBtn';

//import user from '../../img/user.png';
import userDefault from '../../img/user_default.jpg';

import { useSelector, useDispatch } from 'react-redux';
import { setNavbar } from '../../redux/actions/pageState';

const Navbar = () => {

    const [img, setImg] = useState(userDefault);

    const username = useSelector((state) => state.appReducer.user.name);
    const profileImg = useSelector((state) => state.appReducer.user.profile_img);
    const isOpen = useSelector((state) => state.pageState.isOpenNavbar);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        if (profileImg) {
            setImg(profileImg);
        }
    },[profileImg]);

    const seeNav = () =>{
        dispatch(
            setNavbar({isOpenNavbar: !isOpen})
        );
    };

    const closeNavbar = () =>{
        dispatch(
            setNavbar({isOpenNavbar: false})
        );
    };

    return (
        <section className={`navbar ${isOpen ? '' : 'nav-close'}`}>
            <div className="nav-see">
                {isOpen && <RiMenuFoldLine className='icon' onClick={seeNav}/>}
                {!isOpen && <RiMenuUnfoldLine className='icon' onClick={seeNav}/>}

            </div>
            <section className='user-info'>
                <h2 className='welcome'>Ciao, {username}</h2>
                <div className="img-container">
                    <img src={img} alt="User" />
                </div>
            </section>
            <nav className="menu">
                <section className="link-container">

                    <button className='nav-btn' onClick={()=>{
                        navigate('/home');
                        closeNavbar();
                    }}>
                        <div className="line-focus"></div>
                        <span className='text'>I miei piani</span>
                        <IoIosHome className='nav-icon'/>
                    </button>

                    <button className='nav-btn' onClick={()=>{
                        navigate('/profile');
                        closeNavbar();
                    }}>
                        <div className="line-focus"></div>
                        <span className='text'>Profilo</span>
                        <RiUser3Fill className='nav-icon'/>
                    </button> 

                    <button className='nav-btn'onClick={()=>{
                        navigate('/setting');
                        closeNavbar();
                    }}>
                        <div className="line-focus"></div>
                        <span className='text'>Impostazioni</span>
                        <IoIosSettings className='nav-icon'/>
                    </button>

                    <LogoutBtn cssClass='nav-btn logout'>
                        <div className="line-focus"></div>
                        <span className='text'>Logout</span>
                        <RiLogoutBoxRFill className='nav-icon'/>
                    </LogoutBtn>

                </section>
            </nav>
        </section>
    )
}

export default Navbar