import React from 'react';
import './PlanDescription.scss';

import Chart from '../../Chart/Chart';
import FoodDescription from '../../FoodDescription/FoodDescription';

const PlanDescription = ({data}) => {

  return (
    <>
        {
            Object.keys(data).length > 0 &&
            <div className="plan-description">
                <Chart data={data} />
                <FoodDescription data={data} />
            </div>
        }
    </>
  )
}

export default PlanDescription
