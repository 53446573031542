import React, {useState, useEffect} from 'react';

import { useSelector } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



let fields = new Map([
  ["carbohydratesTot", "Carboidrati"],
  ["proteinsTot", "Proteine"],
  ["fatsTot", "Grassi"]
]);

export default function BasicTable({data}) {

  const [rows, setRows] = useState([]);
  const weight = useSelector((state) => state.appReducer.user.weight );
  
  useEffect(()=>{

    let arr = [];

    for (let f in data) {

      if ( fields.has(f) ) {

        let name = fields.get(f);
        let quantity = data[f].quantity;
        let res = 0;

        if (quantity != 0) {
          res = quantity / weight;
          res = res.toFixed(2);
        }
        
        let tmp = {
          name,
          value : res
        }

        arr.push(tmp);
      }
    }

    setRows(arr);

  }, [data, weight])


  return (
    <TableContainer component={Paper} sx={{ maxWidth: 700 }}>
      <Table sx={{ minWidth: 100 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><strong>Macro</strong></TableCell>
            <TableCell align="center"><strong>g/kg</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center" sx={{width:"50%"}}>
                {row.name}
              </TableCell>
              <TableCell align="center" sx={{width:"50%"}}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
