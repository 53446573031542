import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import BeginPage from '../../components/begin-page/BeginPage';

import SignUp from '../../components/Form/signup/SignUp';
import UserData from '../../components/Form/user-data/UserData';

import { useSelector } from 'react-redux';

const SignUpPage = () => {

  const navigate = useNavigate();

  const globalState = useSelector( (state)=>state.appReducer );
  const modalMessage = useSelector((state) => state.pageState.modalMessage);

  const page = globalState.registration.page;

  const [form, setForm] = useState(<SignUp/>);
  const [ redirect, setRedirect ] = useState(false);

  useEffect(()=>{
    if ( redirect && modalMessage === '' ) {
      navigate('/');
    }
  }, [modalMessage, navigate, redirect]);

  useEffect(()=>{
    switch (page){
      case 1:
        setForm(<SignUp/>);
        break;

      case 2:
        setForm(<UserData action={setRedirect} />);
        break;

      default: setForm(<SignUp/>);
    }
  }, [page]);

  return (
    <BeginPage>
      { form }
    </BeginPage>
  )
}

export default SignUpPage