import React from 'react';
import './HeaderSection.scss';

const defaultProps = {
  text : 'text',
  color : '#353535',
  cssClass : '',
  children: <></>
};

const HeaderSection = ({
  text = defaultProps.text,
  color = defaultProps.color,
  cssClass = defaultProps.cssClass,
  children = defaultProps.children
}) => {

    let style = {
        color: color
    };

  return (
    <header className={`header-section ${cssClass}`}>
        <h1 style={style}>{text}</h1>
        <div className="line"></div>
        {children}
    </header>
  )
}

export default HeaderSection