import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './DataBox.scss';

import usePlanRequest from '../../hooks/planRequest';

import { MdExpandMore, MdExpandLess } from "react-icons/md";

import HeaderSection from '../Sections/HeaderSection/HeaderSection';
import HeaderSectionEdit from '../Sections/HeaderSectionEdit/HeaderSectionEdit';
import DivideLine from '../DivideLine/DivideLine';
import ActionBtn from '../ActionBtn/ActionBtn';
import ModalElem from '../ModalElem/ModalElem';
import SearchBar from '../SearchBar/SearchBar';

const defaultProps = {
  title: 'title',
  totalMacro: {
    carbohydratesTot : {quantity:0},
    proteinsTot : {quantity:0},
    fatsTot : {quantity:0},
    kcal : {quantity:0}
  },
  renameBox : (mealCode, value)=>{},
  deleteBox : (mealCode)=>{},
  addElemList : (food, mealCode)=>{},
};

const DataBox = ({ 
  title = defaultProps.title,
  mealCode,
  totalMacro = defaultProps.totalMacro,
  children,
  renameBox = defaultProps.renameBox,
  deleteBox = defaultProps.deleteBox,
  addElemList = defaultProps.addElemList
  
}) => {

  const carbo = totalMacro?.carbohydratesTot?.quantity ? totalMacro.carbohydratesTot.quantity : 0;
  const prot = totalMacro?.proteinsTot?.quantity ? totalMacro.proteinsTot.quantity : 0;
  const fat = totalMacro?.fatsTot?.quantity ? totalMacro.fatsTot.quantity : 0;
  const kcal = totalMacro?.kcal?.quantity ? totalMacro.kcal.quantity : 0;

  const [isOpen, setIsOpen] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const editMode = useSelector((state) => state.pageState.editMode);

  const { getFoods } = usePlanRequest();

  const manageModal = () => {

    setIsOpenModal((prevState)=> !prevState);
  };

  const editMealName = (value) => {

    renameBox(mealCode, value);

  };

  const deleteMeal = () => {

    deleteBox(mealCode);
  }

  const insertFoodInMeal = (food) => {
    manageModal();

    addElemList(food, mealCode);

  }

  // It ensures that only on the first rendering all boxes are closed

  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (editMode) {
      
      if (window.innerWidth > 600 ) {

        setIsOpen(true);
      }
    }
  }, [editMode]);

  const setBox = () => {
    setIsOpen((prevState) => {
      return !prevState;
    });
  };

  const writeTotal = () => {

    return (
      <>
        <div key='0'>
          <span>{kcal}</span>
          <DivideLine height='50%' />
        </div>
        <div key='1'>
          <span>{carbo}</span>
          <DivideLine height='50%' />
        </div>
        <div key='2'>
          <span>{prot}</span>
          <DivideLine height='50%' />
        </div>
        <div key='3'>
          <span>{fat}</span>
        </div>
      </>
    );
  };

  return (
    <div className={`data-box ${isOpen ? '' : 'box-close'}`}>

      {
        isOpenModal && <ModalElem action={manageModal}>
          <div className="box">
            <section className="section-head">
              <h3>Cerca alimento:</h3>
              <div className='search-bar-container'>
                <SearchBar getData={getFoods} action={insertFoodInMeal}/>
              </div>

            </section>
          </div>
        </ModalElem>
      }
      <div className="box-head">
        <div
          className="box-see"
          onClick={setBox}
        >
          {isOpen ?
            <MdExpandLess className='icon' />
            :
            <MdExpandMore className='icon' />
          }
        </div>

        {
          editMode ?
              <div className="box-head-edit" >
                <HeaderSectionEdit text={title} action={editMealName}/>
                <ActionBtn type='remove' action={deleteMeal} />
              </div> 
            :
            <HeaderSection text={title} cssClass='box-header' />
        }

      </div>
      <div className="box-body">
        {children}
        {
          editMode && <div className='add-container'>
            <ActionBtn type='add' cssClass='cl-primary' action={manageModal}/>
          </div>
        }
      </div>
      <div className="box-foot">
        {writeTotal()}
      </div>
    </div>
  )
}

export default DataBox