import React, { useEffect, useState } from 'react';
import './PlanPage.scss';

import { useSelector, useDispatch } from 'react-redux';
import { setPlan, resetMealAction } from '../../redux/actions/appData';
import { setEditMode, setLoading } from '../../redux/actions/pageState';
import { initialState } from '../../redux/actions/appData';

import { useNavigate, useParams } from 'react-router-dom';

/*------------------------------CUSTOM-HOOKS------------------------------*/

import useCalculateTotal from '../../hooks/planTotal';
import usePlanRequest from '../../hooks/planRequest';

/*--------------------------------CONTEXT---------------------------------*/

import {PlanProvider} from './PlanPageContext.js';

/*-------------------------------COMPONENTS-------------------------------*/

import ActionBtn from '../../components/ActionBtn/ActionBtn';

import DefaultPage from '../default-page/DefaultPage.js';

import PlanMeals from '../../components/Sections/PlanMeals/PlanMeals.js';
import BoxHeader from '../../components/Sections/BoxHeader/BoxHeader';

import PlanDescription from '../../components/Sections/PlanDescription/PlanDescription.js';
import ModalChange from '../../components/Sections/ModalChange/ModalChange.js';
import BasicTable from '../../components/BasicTable/BasicTable.js';


//const baseUrl = process.env.REACT_APP_SERVER;

const PlanPage = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  let id = params?.id ? params.id : '';

  const { getPlan, deletePlan, editPlan, editMeals } = usePlanRequest();

  const userId = useSelector((state) => state.appReducer.id);

  const plan = useSelector((state) => state.appData.currentPlan);
  const editMode = useSelector((state) => state.pageState.editMode);
  const defaultPlan = initialState.currentPlan;

  const [ allSums, totalCount ] = useCalculateTotal(plan.meals);
  
  const [ editPlanName, setEditPlanName ] = useState(false);

  useEffect(()=>{
    return ()=>{
      dispatch(resetMealAction());
    };
  }, []);

  useEffect(() => {

    if (userId) {

      dispatch(setLoading(true));
	  
	    getPlan(id).then(()=>{
        dispatch(setLoading(false));
      });

    }

    return () => {
      dispatch(setPlan(defaultPlan));
	    dispatch(setEditMode({ editMode: false }));
	  
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);


  const handleSave = async () => {
	  
    dispatch(setLoading(true));

    if (plan.updated) {
      await editPlan();
    }

    await editMeals();

    dispatch(setLoading(false));

    dispatch(resetMealAction());
    dispatch(setEditMode({editMode: !editMode}));
  }

  const handleDiscard = async () => {

    dispatch(setLoading(true));

    await getPlan(id, false);

    dispatch(setLoading(false));
    
    dispatch(resetMealAction());
    dispatch(setEditMode({editMode: !editMode}));
  };

  const savePlanName = (value) => {
	  dispatch(setPlan({ name: value, updated:true }));
  }

  const handleDeletePlan = () =>{

    let message = "Vuoi davvero eliminare: " + plan.name;
    let question = window.confirm(message);
    
    if (question) {
      deletePlan().then((res)=>{
        navigate('/home');
      });
    }
  };

  return (
    <PlanProvider>
      {
        editPlanName &&
        <ModalChange
          closeModal={() => { setEditPlanName(false) }}
          action={savePlanName}
          placeHolder={plan.name}
          text='Modifica nome'
        />
      }

      <DefaultPage title={'Piano: ' + plan.name} >
        {
          editMode && <ActionBtn cssClass='edit-plan-name-btn' type='edit' action={() => { setEditPlanName(true) }} />
        }

        <div className="plan-page">

          {
            editMode ?
              <div className="btn-container">
                <div
                  className='btn btn-success'
                  onClick={handleSave}
                >
                  <ActionBtn type='save' />
                </div>
                <div
                  className='btn btn-danger'
                  onClick={handleDiscard}
                >
                  <ActionBtn type='discard' />
                </div>
              </div>
              :

              <div
                className='btn edit-plans-btn'
                onClick={() => { dispatch(setEditMode({ editMode: !editMode })) }}
              >
                <ActionBtn type='edit' />
              </div>
          }

          {plan.meals.length > 0 && <BoxHeader />}

          <PlanMeals allSums={allSums} />

          {
            (editMode) &&

            <div className="delete-plan-section data-box-width">
              <button className="btn btn-danger-full" onClick={handleDeletePlan}>Elimina piano</button>
            </div>
          }

          <PlanDescription data={totalCount} />

          {
            Object.keys(totalCount).length > 0 &&

            <div className="page-container flex-center">
              <BasicTable data={totalCount}/>
            </div>
          }
          
          {/* <div className="shareable-container flex-center">
              <label htmlFor="shareable">Rendi condivisibile</label>
              <input type="checkbox" name="shareable" id="shareable"/>
          </div> */}
          
        </div>
      </DefaultPage>
    </PlanProvider>
  )


}

export default PlanPage