import React from 'react';
import './PlanMeals.scss';

import { useSelector } from 'react-redux';
import usePlanActions from '../../../hooks/planActions';

/*-------------------------------COMPONENTS-------------------------------*/

import BoxGroup from '../BoxGroup/BoxGroup';
import ActionBtn from '../../ActionBtn/ActionBtn';

const PlanMeals = ({allSums}) => {

    const plan = useSelector( (state)=> state.appData.currentPlan );
    const editMode = useSelector((state) => state.pageState.editMode);

    const actions = usePlanActions(); 

  return (
    <>
      <BoxGroup data={plan} allSums={allSums} actions={actions}/>
      {
        editMode && <div className='data-box data-box-none flex-end'>
          <ActionBtn type='add' cssClass='cl-success btn-font' action={actions.createNewMeal} />
        </div>
      }

    </>
  )
}

export default PlanMeals