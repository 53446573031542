import React, {useState} from 'react';
import './SwitchBtn.scss'

const defaultProps = {
    text : '',
    cssClass : '',
    action : ()=>{}
};

const SwitchBtn = ({
    text = defaultProps.text,
    cssClass = defaultProps.cssClass,
    action = defaultProps.action
}) => {
    
    const [ direction, setDirection ] = useState(false);

    const handleChange = ()=>{
        action();
        setDirection((prevState)=>!prevState);
    };
  return (
    <div className={`switch-container ${cssClass}`} onClick={handleChange} onPointerDown={(e)=>{e.preventDefault()}}>
        <span>{text}</span>
        <div className="switch-btn" >
            <div className={`circle ${direction && 'right' }`}></div>
        </div>
    </div>
  )
}

export default SwitchBtn